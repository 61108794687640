import Vue from 'vue'
import VueRouter from 'vue-router'
import List from '../views/List.vue'
import Detail from '../views/Detail.vue'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'list',
    component: List
  },
  {
    path: '/datail',
    name: 'detail',
    component: Detail
  },
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
 next()
})


export default router
