var list = [{
    id: 0,
    name: '380',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/380/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/380.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/380.gif',
    middlePics: [{
      name: 'DUOMETRE',
      subName: 'CHRONOGRAPHE',
      cnName: '双翼系列',
      cnSubName: '计时腕表',
      tcName: '雙翼系列',
      tcSubName: '計時腕錶',
      krName: '듀오미터 크로노그래프',
      krSubName: '',
      jpName: 'デュオメトル',
      jpSubName: 'クロノグラフ',
      deName: 'DUOMETRE',
      deSubName:'CHRONOGRAPHE',
      esName: 'DUOMÈTRE',
      esSubName: 'CRONÓGRAFO',
      itName: 'DUOMÈTRE',
      itSubName: 'CHRONOGRAPHE',
      frName: 'DUOMÈTRE',
      frSubName: 'CHRONOGRAPHE',
      src: '/images/380/1.png'
    }, {
      name: 'DUOMETRE',
      subName: 'UNIQUE TRAVEL TIME',
      cnName: '双翼系列',
      cnSubName: '寰宇旅行时间腕表',
      tcName: '雙翼系列',
      tcSubName: '寰宇旅行時間腕錶',
      krName: '듀오미터 유니크 트래블 타임',
      krSubName: '',
      jpName: 'デュオメトル',
      jpSubName: 'ユニーク・トラベルタイム',
      deName: 'DUOMETRE',
      deSubName: 'UNIQUE TRAVEL TIME',
      esName: 'DUOMÈTRE',
      esSubName: 'UNIQUE TRAVEL TIME',
      itName: 'DUOMÈTRE',
      itSubName: 'UNIQUE TRAVEL TIME',
      frName: 'DUOMÈTRE',
      frSubName: 'UNIQUE TRAVEL TIME',
      src: '/images/380/2.png'
    }, {
      name: 'DUOMETRE',
      subName: 'QUANTIEME LUNAIRE',
      cnName: '双翼系列',
      cnSubName: '月相日历腕表',
      tcName: '雙翼系列',
      tcSubName: '月相日曆腕錶',
      krName: '듀오미터 퀀템 루너',
      krSubName: '',
      jpName: 'デュオメトル',
      jpSubName: 'カンティエーム・ルネール',
      deName: 'DUOMETRE',
      deSubName: 'QUANTIEME LUNAIRE',
      esName: 'DUOMÈTRE',
      esSubName: 'QUANTIÈME LUNAIRE',
      itName: 'DUOMÈTRE',
      itSubName: 'QUANTIÈME LUNAIRE',
      frName: 'DUOMÈTRE',
      frSubName: 'QUANTIÈME LUNAIRE',
      src: '/images/380/3.png'
    }],
    overview: ['Manual', '50 hours', '445', '6.95 mm'],
    functions: ['Hour counter', 'Chronograph', '60-minute counter', '60-second counter', 'Hour - Minute', 'Seconds', 'Jumping seconds', 'Twin Power-Reserve'],
    description: ["THE DUOMÈTRE CALIBRE IS EQUIPPED WITH THE DUAL WING CONCEPT - AS A WORTHY DESCENDANT OF OLDER LECOULTRE'S POCKET WATCH MODELS, IT IS A HIGH-PERFORMING CHRONOGRAPH."],
    cnOverview: ['手动上链', '50小时', '445个', '6.95毫米'],
    cnFunctions: ['小时计数器', '计时器', '60分钟计时器', '60秒钟计时器', '时、分显示', '秒钟', '跳秒', '双动力储存'],
    cnDescription: ['DUOMÈTRE双翼机芯继承了积家老式怀表的Dual-Wing双翼设计理念，是一款高性能的计时机芯。'],
    tcOverview: ['手動上鏈', '50小時', '445', '6.95毫米'],
    tcFunctions: ['小時計時器', '計時器', '60 分鐘計時器', '60 秒鐘計時器', '時 - 分', '秒', '跳秒', '雙動力儲存'],
    tcDescription: ['Duomètre 雙翼系列計時腕錶機芯配備雙翼概念——作為繼承LECOULTRE 懷錶的作品，是一款高性能計時器。'],
    krOverview: ['매뉴얼', '50', '445', '6.95mm'],
    krFunctions: ['시간 카운터', '크로노그래프', '60분 카운터', '60초 카운터', '시 - 분', '초', '점핑 세컨즈', '트윈 파워 리저브'],
    krDescription: ['듀오미터 칼리버는 듀얼 윙 콘셉트를 갖추고 있습니다. 르쿨트르 포켓 워치의 후속 모델로, 고성능 크로노그래프 기능을 갖추고 있습니다.'],
    jpFunctions: ['時間積算カウンター', 'クロノグラフ', '60分積算カウンター', '60秒積算カウンター ', '時 - 分', '秒', 'ジャンピングセコンド', 'ツインパワーリザーブ'],
    jpOverview: ['手巻', '最大50時間', '445', '6.95mm'],
    jpDescription: ['デュオメトルキャリバーはデュアル・ウィングコンセプトを採用しており、ジャガー・ルクルトの懐中時計モデルの設計を受け継ぐ高性能クロノグラフです。'],
    deFunctions: ['Stundenzähler', 'Chronograph', '60-Minuten-Zähler', '60-Sekunden-Zähler', 'Stunde - Minute', 'Sekunden', 'Springende Sekunde', 'Doppelte Gangreserve'],
    deOverview: ['Handaufzug', '50', '445', '6.95 mm'],
    deDescription: ['Das Kaliber Duomètre ist mit dem Dual Wing-Konzept ausgestattet und ist als leistungsstarker Chronograph ein würdiger Nachfahre klassischer Taschenuhrmodelle von LeCoultre.'],
    esFunctions: ['Contador de horas','Cronógrafo','Contador de 60 minutos','Contador de 60 segundos','Horas - Minutos','Segundos','Segundos saltantes','Reserva de marcha doble'],
    esOverview: ['Manual','50','445','6.95 mm'],
    esDescription: ['El calibre Duomètre está equipado con el concepto Dual-Wing. Como digno descendiente de los antiguos modelos de relojes de bolsillo de LeCoultre, se trata de un cronógrafo de alto rendimiento.'],
    itFunctions: ['Contatore delle ore','Cronografo','Contatore dei 60 minuti','Contatore dei 60 secondi','Ore, minuti','Secondi','Secondi saltanti','Riserva di carica doppia'],
    itOverview: ['Manuale','50','445','6,95 mm'],
    itDescription: ['Il calibro Duomètre è dotato del concetto Dual Wing: degno discendente dei vecchi modelli di orologi da taschino LeCoultre, è un cronografo dalle elevate prestazioni.'],
    frFunctions: ['Compteur des heures','Chronographe','Compteur 60 minutes','Compteur 60 secondes','Heures et minutes','Secondes','Secondes sautantes','Double réserve de marche'],
    frOverview: ['Remontage manuel','50','445','6,95 mm'],
    frDescription: ['Digne héritier des anciennes montres de poche de LeCoultre, le calibre Duomètre, équipé du concept Dual-Wing, est un chronographe très performant.'],
    bottomPic: '/images/380/bottom.png',
  },
  {
    id: 1,
    name: '759',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/759/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/759.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/759.gif',
    middlePics: [{
      name: 'MASTER',
      subName: ' CONTROL CHRONOGRAPH CALENDAR',
      cnName: '大师系列',
      cnSubName: '计时全历腕表',
      tcName: '大師系列',
      tcSubName: '計時日曆腕錶',
      krName: '마스터 컨트롤 크로노그래프 캘린더',
      krSubName: '',
      jpName: 'マスター',
      jpSubName: 'コントロール・クロノグラフ・カレンダー',
      deName: 'MASTER',
      deSubName: 'CONTROL CHRONOGRAPH CALENDAR',
      esName: 'MASTER',
      esSubName: 'CONTROL CHRONOGRAPH CALENDAR',
      itName: 'MASTER',
      itSubName: 'CONTROL CHRONOGRAPH CALENDAR',
      frName: 'MASTER',
      frSubName: 'CONTROL CHRONOGRAPHE CALENDRIER',
      src: '/images/759/1.png'
    }, {
      name: 'DUOMETRE',
      subName: 'CHRONOGRAPHE',
      cnName: '双翼系列',
      cnSubName: '计时腕表',
      tcName: '雙翼系列',
      tcSubName: '計時腕錶',
      krName: '듀오미터 크로노그래프',
      krSubName: '',
      jpName: 'デュオメトル',
      jpSubName: 'クロノグラフ',
      deName: 'DUOMETRE',
      deSubName: 'CHRONOGRAPHE',
      esName: 'CRONÓGRAFO',
      esSubName: 'DUOMÈTRE',
      itName: 'DUOMÈTRE',
      itSubName: 'CHRONOGRAPHE',
      frName: 'DUOMÈTRE',
      frSubName: 'CHRONOGRAPHE',
      src: '/images/759/2.png'
    }, {
      name: 'POLARIS',
      subName: 'CHRONOGRAPH',
      cnName: '北宸系列',
      cnSubName: '计时腕表',
      tcName: '北宸系列',
      tcSubName: '計時腕錶',
      krName: '폴라리스 크로노그래프',
      krSubName: '',
      jpName: 'ポラリス',
      jpSubName: 'クロノグラフ',
      deName: 'POLARIS',
      deSubName: 'CHRONOGRAPH',
      esName: 'POLARIS',
      esSubName: 'CHRONOGRAPH',
      itName: 'POLARIS',
      itSubName: 'CHRONOGRAPH',
      frName: 'POLARIS',
      frSubName: 'CHRONOGRAPHE',
      src: '/images/759/3.png'
    }],
    overview: ['Automatic', '65 hours', '356', '6.83 mm'],
    functions: ['Chronograph', '30-minute counter', 'Date', 'Hour - Minute', 'Day', 'Month', 'Moon phases', 'Pulsimeter', 'Seconds'],
    description: ['MANUFACTURE CALIBRE ASSOCIATING 2 TRADITIONAL WATCHMAKING COMPLICATIONS: A CHRONOGRAPH AND OUR SIGNATURE CALENDAR.'],
    cnOverview: ['自动上链', '65小时', '356个', '6.83毫米'],
    cnFunctions: ['计时器', '30分钟计时器', '日期', '时、分显示', '星期', '月份', '月相', '脉搏计', '秒钟'],
    cnDescription: ['积家大工坊机芯将两大传统制表复杂功能巧妙结合：计时码表与积家标志性的全历显示。'],
    tcOverview: ['自動上鏈', '65 小時', '356', '6.83毫米'],
    tcFunctions: ['計時器', '30 分鐘計時器', '日期', '時 - 分', '日', '月', '月相功能', '脈搏計', '秒'],
    tcDescription: ['結合兩種傳統製錶複雜功能的積家錶廠機芯：計時器和我們的經典日曆。'],
    krOverview: ['오토매틱', '65', '356', '6.83mm'],
    krFunctions: ['크로노그래프 ', '30분 카운터', '날짜', '시 - 분', '일', '월', '문페이즈', '맥박계', '초'],
    krDescription: ['2개의 전통적인 워치메이킹 컴플리케이션인 크로노그래프와 시그니처 캘린더를 결합하는 매뉴팩처 칼리버.'],
    jpFunctions: ['クロノグラフ','30分カウンター','日付','時-分表示','日付','月','ムーンフェイズ','パルスメーター','秒表示'],
    jpOverview: ['自動巻', '最大45時間', '365', '6.83mm'],
    jpDescription: ['2つの伝統的な複雑機構であるクロノグラフとジャガー・ルクルトを象徴するカレンダーに関連するキャリバーを製造。'],
    deFunctions: ['Chronograph', '30-Minuten-Zähler', 'Datum', 'Stunde - Minute', 'Tag', 'Monat', 'Mondphasen', 'Pulsmesser', 'Sekunden'],
    deOverview: ['Automatik', '65', '356', '6.83 mm'],
    deDescription: ['Manufakturkaliber, das zwei traditionelle Uhrenkomplikationen vereint: einen Chronographen und unseren charakteristischen Kalender.'],
    esFunctions: ['Cronógrafo','Contador de 30 minutos', 'Fecha', 'Horas - Minutos', 'Día', 'Mes', 'Fases lunares', 'Pulsómetro', 'Segundos'],
    esOverview: ['Automático', '65', '356', '6.83 mm'],
    esDescription: ['Calibre de la Manufactura que combina dos tradicionales complicaciones relojeras: un cronógrafo y nuestro emblemático calendario.'],
    itFunctions: ['Cronografo','Contatore dei 30 minuti','Data','Ore, minuti','Giorno','Mese','Fasi lunari','Pulsimetro','Secondi'],
    itOverview: ['Automatico', '65', '356', '6,83 mm'],
    itDescription: ['Calibro di Manifattura che associa due complicazioni orologiere tradizionali: un cronografo e il nostro esclusivo calendario.'],
    frFunctions: ['Chronographe','Compteur 30 minutes','Date','Heures et minutes','Jour','Mois','Phases de lune','Pulsomètre','Secondes'],
    frOverview: ['Remontage automatique', '65', '356', '6,83 mm'],
    frDescription: ['Calibre Manufacture associant deux complications horlogères classiques : un Chronographe et notre Calendrier signature.'],
    bottomPic: '/images/759/bottom.png',
  },
  {
    id: 2,
    name: '822/2',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/822/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/822_2.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/822_2.gif',
    middlePics: [{
      name: 'REVERSO',
      subName: 'TRIBUTE MONOFACE SMALL SECONDS',
      cnName: '翻转系列',
      cnSubName: '小秒针腕表',
      tcName: '翻轉系列',
      tcSubName: '小秒針腕錶',
      krName: '리베르소 트리뷰트 모노페이스 스몰 세컨즈',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'トリビュート・スモールセコンド',
      deName: 'REVERSO',
      deSubName: 'TRIBUTE MONOFACE SMALL SECONDS',
      esName: 'REVERSO',
      esSubName: 'TRIBUTE MONOFACE SMALL SECONDS',
      itName: 'REVERSO',
      itSubName: 'TRIBUTE MONOFACE SMALL SECONDS',
      frName: 'REVERSO',
      frSubName: 'TRIBUTE MONOFACE PETITE SECONDE',
      src: '/images/822/1.png'
    }, {
      name: 'REVERSO',
      subName: 'CLASSIC MONOFACE SMALL SECONDS',
      cnName: '翻转系列',
      cnSubName: '小秒针腕表',
      tcName: '翻轉系列',
      tcSubName: '小秒針腕錶',
      krName: '리베르소 클래식 모노페이스 스몰 세컨즈',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'クラシック・モノフェイス・スモールセコンド',
      deName: 'REVERSO',
      deSubName: 'CLASSIC MONOFACE SMALL SECONDS',
      esName: 'REVERSO',
      esSubName: 'CLASSIC MONOFACE SMALL SECONDS',
      itName: 'REVERSO',
      itSubName: 'CLASSIC MONOFACE SMALL SECONDS',
      frName: 'REVERSO',
      frSubName: 'CLASSIC MONOFACE PETITE SECONDE',
      src: '/images/822/2.png'
    }, {
      name: 'REVERSO',
      subName: 'ONE MONOFACE',
      cnName: '翻转系列',
      cnSubName: '腕表',
      tcName: '翻轉系列',
      tcSubName: '腕錶',
      krName: '리베르소 원 모노페이스',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'ワン・モノフェイス',
      deName: 'REVERSO',
      deSubName: 'ONE MONOFACE',
      esName: 'REVERSO',
      esSubName: 'ONE MONOFACE',
      itName: 'REVERSO',
      itSubName: 'ONE MONOFACE',
      frName: 'REVERSO',
      frSubName: 'ONE MONOFACE',
      src: '/images/822/3.png'
    }],
    overview: ['Manual', '42 hours', '111', '2.94 mm'],
    functions: ['Hour - Minute', 'Seconds'],
    description: ['REFERENCE CALIBRE OF REVERSO WITH A SIGNATURE BRIDGE.'],
    cnOverview: ['手动上链', '42小时', '111个', '2.94毫米'],
    cnFunctions: ['时、分显示', '秒钟'],
    cnDescription: ['作为REVERSO翻转腕表系列的经典机芯，搭载积家标志性表桥。'],
    tcOverview: ['手動上鏈', '42 小時', '111', '2.94毫米'],
    tcFunctions: ['時 - 分', '秒'],
    tcDescription: ['積家 Reverso 翻轉系列腕錶的參考機芯，帶有標誌性的錶橋。'],
    krOverview: ['매뉴얼 ', '42', '111', '2.94mm'],
    krFunctions: ['시 - 분', '초 '],
    krDescription: ['시그니처 브리지가 있는 리베르소의 레퍼런스 칼리버.'],
    jpFunctions: ['時-分表示','秒表示'],
    jpOverview: ['手巻', '最大42時間', '111', '2.94mm'],
    jpDescription: ['レベルソのキャリバーを象徴的なブリッジとともに詳しく見る。'],
    deFunctions: ['Stunde - Minute', 'Sekunden'],
    deOverview: ['Handaufzug', '42', '111', '2.94 mm'],
    deDescription: ['Reverso Original-Kaliber mit charakteristischer Brücke.'],
    esFunctions: ['Horas - Minutos', 'Segundos'],
    esOverview: ['Manual', '42' , '111' , '2.94 mm'],
    esDescription: ['Calibre de referencia del Reverso con un emblemático puente.'],
    itFunctions: ['Ore, minuti', 'Secondi'],
    itOverview: ['Manuale', '42' , '111' ,'2,94 mm'],
    itDescription: ['Calibro di riferimento del Reverso con un ponte rifinito.'],
    frFunctions: ['Heures et minutes', 'Secondes'],
    frOverview: ['Remontage manuel', '42' , '111' ,'2,94 mm'],
    frDescription: ['Calibre de référence de la collection Reverso avec un pont signature.'],
    bottomPic: '/images/822/bottom.png',
  },
  {
    id: 3,
    name: '846',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/846/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/846.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/846.gif',
    middlePics: [{
      name: 'REVERSO',
      subName: 'CLASSIC MONOFACE',
      cnName: '翻转系列',
      cnSubName: '小秒针腕表',
      tcName: '翻轉系列',
      tcSubName: '腕錶',
      krName: '리베르소 클래식 모노페이스 ',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'クラシック・モノフェイス',
      deName: 'REVERSO',
      deSubName: 'CLASSIC MONOFACE',
      esName: 'REVERSO',
      esSubName: 'CLASSIC MONOFACE',
      itName: 'REVERSO',
      itSubName: 'CLASSIC MONOFACE',
      frName: 'REVERSO',
      frSubName: 'CLASSIC MONOFACE',
      src: '/images/846/1.png'
    }, {
      name: 'REVERSO',
      subName: 'CLASSIC MONOFACE',
      cnName: '翻转系列',
      cnSubName: '小秒针腕表',
      tcName: '翻轉系列',
      tcSubName: '腕錶',
      krName: '리베르소 클래식 모노페이스 ',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'クラシック・モノフェイス',
      deName: 'REVERSO',
      deSubName: 'CLASSIC MONOFACE',
      esName: 'REVERSO',
      esSubName: 'CLASSIC MONOFACE',
      itName: 'REVERSO',
      itSubName: 'CLASSIC MONOFACE',
      frName: 'REVERSO',
      frSubName: 'CLASSIC MONOFACE',
      src: '/images/846/2.png'
    }, {
      name: 'REVERSO',
      subName: 'CLASSIC MONOFACE',
      cnName: '翻转系列',
      cnSubName: '腕表',
      tcName: '翻轉系列',
      tcSubName: '腕錶',
      krName: '리베르소 클래식 모노페이스 ',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'クラシック・モノフェイス',
      deName: 'REVERSO',
      deSubName: 'CLASSIC MONOFACE',
      esName: 'REVERSO',
      esSubName: 'CLASSIC MONOFACE',
      itName: 'REVERSO',
      itSubName: 'CLASSIC MONOFACE',
      frName: 'REVERSO',
      frSubName: 'CLASSIC MONOFACE',
      src: '/images/846/3.png'
    }],
    overview: ['Manual', '38 hours', '93', '2.9 mm'],
    functions: ['Hour - Minute'],
    description: ['THE SMALL-SIZE CALIBRE USED IN JAEGER-LECOULTRE REVERSO, THE RECTANGULAR SHAPED WATCH.'],
    cnOverview: ['手动上链', '38小时', '93个', '2.9毫米'],
    cnFunctions: ['时、分显示'],
    cnDescription: ['这款小型机芯，被应用于包括积家REVERSO翻转腕表系列在内的长方形表款。'],
    tcOverview: ['手動上鏈', '38 小時', '93', '2.9毫米'],
    tcFunctions: ['時 - 分'],
    tcDescription: ['擁有長方形錶殼的積家 Reverso 翻轉系列腕錶中使用的小型機芯。'],
    krOverview: ['매뉴얼', '38', '93', '2.9mm'],
    krFunctions: ['시 - 분'],
    krDescription: ['직사각 형태의 워치인 예거 르쿨트르 리베르소에 사용된 스몰 사이즈 칼리버.'],
    jpFunctions: ['時-分表示'],
    jpOverview: ['手巻', '最大38時間', '93', '2.9mm'],
    jpDescription: ['長方形型の時計であるジャガー・ルクルトのレベルソに搭載された小型キャリバー。'],
    deFunctions: ['Stunde - Minute'],
    deOverview: ['Handaufzug', '38', '93', '2.9 mm'],
    deDescription: ['Das kleine Kaliber, das die Jaeger-LeCoultre Reverso, unsere rechteckige Uhr, ausstattet.'],
    esFunctions: ['Horas - Minutos'],
    esOverview: ['Manual','38','93','2.9 mm'],
    esDescription: ['El calibre de tamaño pequeño usado en el Reverso de Jaeger-LeCoultre, el reloj de forma rectangular.'],
    itFunctions: ['Ore, minuti'],
    itOverview: ['Manuale','38','93','2,9 mm'],
    itDescription: ['Il calibro di piccole dimensioni utilizzato nel Reverso di Jaeger-LeCoultre, l’orologio di forma rettangolare.'],
    frFunctions: ['Heures et minutes'],
    frOverview: ['Remontage manuel','38','93','2,9 mm'],
    frDescription: ['Petit calibre utilisé dans la montre Jaeger-LeCoultre Reverso de forme rectangulaire.'],
    bottomPic: '/images/846/bottom.png',
  },
  {
    id: 4,
    name: '849A',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/849/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/849A.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/849A.gif',
    middlePics: [{
      name: 'MASTER',
      subName: 'ULTRA THIN KNIFE',
      cnName: '超薄大师系列',
      cnSubName: '王者之刃腕表',
      tcName: '超薄大師系列',
      tcSubName: '王者之劍腕錶',
      krName: '마스터 울트라 씬 나이프',
      krSubName: '',
      jpName: 'マスター',
      jpSubName: 'ウルトラスリム・ナイフ',
      deName: 'MASTER',
      deSubName: 'ULTRA THIN KNIFE',
      esName: 'MASTER',
      esSubName: 'ULTRA THIN  KNIFE',
      itName: 'MASTER',
      itSubName: 'ULTRA THIN KNIFE',
      frName: 'MASTER',
      frSubName: 'ULTRA THIN KNIFE',
      src: '/images/849/1.png'
    }, {
      name: 'MASTER',
      subName: 'ULTRA THIN SQUELETTE',
      cnName: '超薄大师系列',
      cnSubName: '镂空腕表',
      tcName: '超薄大師系列',
      tcSubName: '縷空腕錶',
      krName: '마스터 울트라 씬 스켈레톤',
      krSubName: '',
      jpName: 'マスター',
      jpSubName: 'ウルトラスリム・スケルトン',
      deName: 'MASTER',
      deSubName: 'ULTRA THIN SQUELETTE',
      esName: 'MASTER',
      esSubName: 'ULTRA THIN SQUELETTE',
      itName: 'MASTER',
      itSubName: 'ULTRA THIN SQUELETTE',
      frName: 'MASTER',
      frSubName: 'ULTRA THIN SQUELETTE',
      src: '/images/849/2.png'
    }, {
      name: 'MASTER',
      subName: 'ULTRA THIN SQUELETTE',
      cnName: '超薄大师系列',
      cnSubName: '镂空腕表',
      tcName: '超薄大師系列',
      tcSubName: '縷空腕錶',
      krName: '마스터 울트라 씬 스켈레톤',
      krSubName: '',
      jpName: 'マスター',
      jpSubName: 'ウルトラスリム・スケルトン',
      deName: 'MASTER',
      deSubName: 'ULTRA THIN SQUELETTE',
      esName: 'MASTER',
      esSubName: 'ULTRA THIN SQUELETTE',
      itName: 'MASTER',
      itSubName: 'ULTRA THIN SQUELETTE',
      frName: 'MASTER',
      frSubName: 'ULTRA THIN SQUELETTE',
      src: '/images/849/3.png'
    }],
    overview: ['Manual', '33 hours', '119', '1.85 mm'],
    functions: ['Hour - Minute'],
    description: ['THE 849A IS AN EXTRA THIN CALIBRE DEMONSTRATING THE SKILLS OF THE MANUFACTURE MASTER CRAFTSMEN IN TERMS OF MINIATURIZATION TO REACH EXTREME THINNESS.'],
    cnOverview: ['手动上链', '33小时', '119个', '1.85毫米'],
    cnFunctions: ['时、分显示'],
    cnDescription: ['积家 849A 型机芯是一款超薄机芯，展示了积家表厂制表大师在微型化以达到极致纤薄方面的技能。'],
    tcOverview: ['手動上鏈', '33 小時', '119', '1.85毫米'],
    tcFunctions: ['時 - 分'],
    tcDescription: ['積家 849A 型機芯是一款超薄機芯，展示了積家錶廠製錶大師在微型化以達到極致纖薄方面的技能。'],
    krOverview: ['매뉴얼', '33', '119', '1.85mm'],
    krFunctions: ['시 - 분'],
    krDescription: ['849A는 극도로 얇은 두께를 구현한, 매뉴팩처 마스터 장인의 소형화 기술력을 보여주는 칼리버입니다.'],
    jpFunctions: ['時-分表示'],
    jpOverview: ['手巻', '最大33時間', '119', '1.85mm'],
    jpDescription: ['849Aは極薄なキャリバーで、この薄さの実現を可能とするマニュファクチュールの卓越した技巧を体現する存在です。'],
    deFunctions: ['Stunde - Minute'],
    deOverview: ['Handaufzug', '33', '119', '1.85 mm'],
    deDescription: ['Das 849A ist ein extra flaches Kaliber, das die Fähigkeiten der Meisteruhrmacher der Manufaktur im Bereich der Miniaturisierung unter Beweis stellt.'],
    esFunctions: ['Horas - Minutos'],
    esOverview: ['Manual','33','119','1.85 mm'],
    esDescription: ['El 849A es un calibre extrafino que revela la capacidad de miniaturización de los maestros artesanos de la Manufactura para conseguir grosores sumamente finos.'],
    itFunctions: ['Ore, minuti'],
    itOverview: ['Manuale','33','119','1,85 mm'],
    itDescription: ['L’849A è un calibro ultrapiatto che dimostra il savoir-faire dei maestri artigiani della Manifattura in termini di miniaturizzazione per raggiungere la massima sottigliezza.'],
    frFunctions: ['Heures et minutes'],
    frOverview: ['Remontage manuel','33','119','1,85 mm'],
    frDescription: ['Le Calibre extra-plat 849A témoigne de la maîtrise de la miniaturisation par les maîtres artisans de la Manufacture, pour obtenir un format ultraplat.'],
    bottomPic: '/images/849/bottom.png',
  },
  {
    id: 5,
    name: '854A/2',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/854/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/854_A.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/854_A.gif',
    middlePics: [{
      name: 'REVERSO',
      subName: 'TRIBUTE DUOFACE',
      cnName: '双面翻转',
      cnSubName: '腕表',
      tcName: '翻轉系列',
      tcSubName: '雙面腕錶',
      krName: '리베르소 트리뷰트 듀오페이스',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'トリビュート・デュオ',
      deName: 'REVERSO',
      deSubName: 'TRIBUTE DUOFACE',
      esName: 'REVERSO',
      esSubName: 'TRIBUTE DUOFACE',
      itName: 'REVERSO',
      itSubName: 'TRIBUTE DUOFACE',
      frName: 'REVERSO',
      frSubName: 'TRIBUTE DUOFACE',
      src: '/images/854/1.png'
    }, {
      name: 'REVERSO',
      subName: 'CLASSIC DUOFACE SMALL SECONDS',
      cnName: '翻转系列',
      cnSubName: '双面双时区小秒针腕表',
      tcName: '翻轉系列',
      tcSubName: '腕錶',
      krName: '리베르소 클래식 듀오페이스 스몰 세컨즈',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'クラシック・デュオ・スモールセコンド',
      deName: 'REVERSO',
      deSubName: 'CLASSIC DUOFACE SMALL SECONDS',
      esName: 'REVERSO',
      esSubName: 'CLASSIC DUOFACE SMALL SECONDS',
      itName: 'REVERSO',
      itSubName: 'CLASSIC DUOFACE SMALL SECONDS',
      frName: 'REVERSO',
      frSubName: 'CLASSIC DUOFACE PETITE SECONDE',
      src: '/images/854/2.png'
    }, {
      name: 'REVERSO',
      subName: 'ONE DUETTO',
      cnName: '双面翻转',
      cnSubName: '腕表',
      tcName: '翻轉系列',
      tcSubName: '腕錶',
      krName: '리베르소 원 듀에토',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'ワン・デュエット',
      deName: 'REVERSO',
      deSubName: 'ONE DUETTO',
      esName: 'REVERSO',
      esSubName: 'ONE DUETTO',
      itName: 'REVERSO',
      itSubName: 'ONE DUETTO',
      frName: 'REVERSO',
      frSubName: 'ONE DUETTO',
      src: '/images/854/3.png'
    }],
    overview: ['Manual', '42 hours', '160', '3.8 mm', '17.2 x 22 mm'],
    functions: ['Second time-zone', 'Hour - Minute', '24-hour display', 'Seconds'],
    description: ['A CALIBRE SPECIFICALLY DESIGNED AND DEVELOPPED IN 1994 FOR THE REVERSO DUOFACE, AN INGENIOUS COMPLICATION DISPLAYING 2 TIMEZONES ON BOTH SIDES OF A WATCH.'],
    cnOverview: ['手动上链', '42小时', '160个', '3.8毫米', '17.2x22毫米'],
    cnFunctions: ['第二时区', '24小时显示', '秒钟'],
    cnDescription: ['1994年，专为REVERSO DUOFACE翻转系列双面双时区腕表设计研发，搭载可在腕表两面显示不同时区时间的创新复杂功能。'],
    tcOverview: ['手動上鏈', '42 小時', '160', '3.8毫米'],
    tcFunctions: ['第二時區', '時 - 分', '24 小時顯示', '秒'],
    tcDescription: ['1994 年專為 Reverso Duoface 翻轉系列雙時區腕錶設計和開發的機芯，這是一款巧妙的複雜功能，可在腕錶的兩面顯示兩個不同時區。'],
    krOverview: ['매뉴얼', '42', '160', '3.8mm'],
    krFunctions: ['세컨드 타임존', '시 - 분', '24시간 디스플레이', '초'],
    krDescription: ['1994년 리베르소 듀오페이스를 위해 특별히 설계 및 개발된 칼리버로, 워치 양면에 2개의 타임존을 표시하는 독창적인 컴플리케이션입니다.'],
    jpFunctions: ['第2時間表示','時-分表示','24時間表示', '秒表示'],
    jpOverview: ['手巻', '最大42時間', '160', '3.8mm'],
    jpDescription: ['1994年にレベルソ・デュオのために特別に設計・開発されたこの精巧な複雑機構は、時計の両サイドで2つのタイムゾーンを表示することが可能です。'],
    deFunctions: ['Zweite Zeitzone', 'Stunde - Minute', '24-Stunden-Anzeige', 'Sekunden'],
    deOverview: ['Handaufzug', '42', '160', '3.8 mm'],
    deDescription: ['Ein Kaliber, das 1994 speziell für die Reverso Duoface entworfen und entwickelt wurde. Eine geniale Komplikation, die zwei Zeitzonen auf beiden Seiten einer Uhr anzeigt.'],
    esFunctions: ['Segundo huso horario','Horas - Minutos','Indicador de 24 horas','Segundos'],
    esOverview: ['Manual','42','160','3.8 mm'],
    esDescription: ['Un calibre especialmente diseñado y desarrollado en 1994 para el Reverso Duoface, una ingeniosa complicación que muestra dos zonas horarias en ambos lados del reloj.'],
    itFunctions: ['Secondo fuso orario','Ore, minuti','Indicatore 24 ore','Secondi'],
    itOverview: ['Manuale','42','160','3,8 mm'],
    itDescription: ['Un calibro appositamente progettato e sviluppato nel 1994 per il Reverso Duoface, un’ingegnosa complicazione che visualizza due fusi orari su entrambi i lati dell’orologio.'],
    frFunctions: ['Second fuseau horaire','Heures et minutes','Affichage 24 heures','Secondes'],
    frOverview: ['Remontage manuel','42','160','3,8 mm'],
    frDescription: ['Calibre spécifiquement conçu et développé en 1994 pour la Reverso Duoface, une complication ingénieuse affichant deux fuseaux horaires sur les deux côtés d’une montre.'],
    bottomPic: '/images/854/bottom.png',
  },
  {
    id: 6,
    name: '918',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/918/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/918.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/918.gif',
    middlePics: [{
      name: 'MASTER',
      subName: 'CONTROL MEMOVOX',
      cnName: '大师系列',
      cnSubName: '响闹腕表',
      tcName: '大師系列',
      tcSubName: '響鬧腕錶',
      krName: '마스터 컨트롤 메모복스',
      krSubName: '',
      jpName: 'マスター',
      jpSubName: 'コントロール・メモボックス',
      deName: 'MASTER',
      deSubName: 'CONTROL MEMOVOX',
      esName: 'MASTER',
      esSubName: 'CONTROL MEMOVOX',
      itName: 'MASTER',
      itSubName: 'CONTROL MEMOVOX',
      frName: 'MASTER',
      frSubName: 'CONTROL MEMOVOX',
      src: '/images/918/1.png'
    }, {
      name: 'POLARIS',
      subName: 'MARINER MEMOVOX',
      cnName: '北宸系列',
      cnSubName: '响闹潜水腕表',
      tcName: '北宸系列',
      tcSubName: '潛水響鬧腕錶',
      krName: '폴라리스 마리너 메모복스',
      krSubName: '',
      jpName: 'ポラリス',
      jpSubName: 'マリナー・メモボックス',
      deName: 'POLARIS',
      deSubName: 'MARINER MEMOVOX',
      esName: 'POLARIS',
      esSubName: 'MARINER MEMOVOX',
      itName: 'POLARIS',
      itSubName: 'MARINER MEMOVOX',
      frName: 'POLARIS',
      frSubName: 'MARINER MEMOVOX',
      src: '/images/918/2.png'
    }, {
      name: 'POLARIS',
      subName: 'DATE',
      cnName: '北宸系列',
      cnSubName: '日历腕表',
      tcName: '北宸系列',
      tcSubName: '日期顯示腕錶',
      krName: '폴라리스 데이트',
      krSubName: '',
      jpName: 'ポラリス',
      jpSubName: 'デイト',
      deName: 'POLARIS',
      deSubName: 'DATE',
      esName: 'POLARIS',
      esSubName: 'DATE',
      itName: 'POLARIS',
      itSubName: 'DATE',
      frName: 'POLARIS',
      frSubName: 'DATE',
      src: '/images/918/3.png'
    }],
    overview: ['Automatic', '45 hours', '260', '7.45 mm'],
    functions: ['Date', 'Hour - Minute', 'Rotating bezel', 'Seconds', 'Alarm'],
    description: ['AUTOMATIC CALIBRE EQUIPPED WITH THE SIGNATURE FUNCTION OF THE MANUFACTURE INVENTED IN 1950: THE MEMOVOX. THIS FUNCTION ACTIVATES AN ALARM AT A TIME SET BY THE WEARER.'],
    cnOverview: ['自动上链', '45小时', '260个', '7.45毫米'],
    cnFunctions: ['日期', '时、分显示', '旋转表圈', '秒钟', '闹钟'],
    cnDescription: ['这款自动机芯，搭载积家大工坊于1950年发明的标志性复杂功能：Memovox响闹功能,这个功能可令闹铃在佩戴者设定的时间鸣响。'],
    tcOverview: ['自動上鏈', '45 小時', '260', '7.45毫米'],
    tcFunctions: ['日期', '時 - 分', '內旋錶圈', '秒', '響鬧功能'],
    tcDescription: ['配備 1950 年發明的積家錶廠經典功能的自動上鏈機芯：Memovox 響鬧機芯。此功能會在佩戴者設定的時間觸發響鬧聲。'],
    krOverview: ['오토매틱', '45', '260', '7.45mm'],
    krFunctions: ['날짜', '시 - 분', '회전형 베젤', '초', '알람'],
    krDescription: ['1950년에 발명된 매뉴팩처의 시그니처 기능인 메모복스를 갖춘 오토매틱 칼리버. 착용자가 설정한 시간에 알람이 울립니다.'],
    jpFunctions: ['日付','時-分表示','回転式ベゼル','秒','アラーム'],
    jpOverview: ['自動巻', '最大45時間', '260', '7.45mm'],
    jpDescription: ['1950年代に開発され、ジャガー・ルクルトの卓越した機能性を象徴するメモボックスを搭載した自動巻キャリバー。この機能は設定した時間にアラームを起動します。'],
    deFunctions: ['Datum', 'Stunde - Minute', 'Drehbare Lünette', 'Sekunden', 'Alarmfunktion'],
    deOverview: ['Automatik', '45', '260', '7.45 mm'],
    deDescription: ['Automatikkaliber mit der für unsere Manufaktur typischen Funktion, die 1950 erfunden wurde: die Memovox. Diese Funktion aktiviert einen Alarm zu einer vom Träger bestimmten Zeit.'],
    esFunctions: ['Fecha','Horas - Minutos','Bisel giratorio','Segundos','Alarma'],
    esOverview: ['Automático', '45', '260','7.45 mm'],
    esDescription: ['Calibre automático equipado con la emblemática función de la Manufactura inventada en 1950: el Memovox. Esta función activa una alarma a una hora ajustada por el usuario.'],
    itFunctions: ['Data','Ore, minuti','Lunetta girevole','Secondi','Sveglia'],
    itOverview: ['Automatico', '45', '260','7,45 mm'],
    itDescription: ['Calibro automatico dotato della funzione simbolo della Manifattura inventata nel 1950: il Memovox. Questa funzione attiva una sveglia all’ora stabilita da chi indossa l’orologio.'],
    frFunctions: ['Date','Heures et minutes','Lunette rotative','Secondes','Réveil'],
    frOverview: ['Remontage automatique', '45', '260','7,45 mm'],
    frDescription: ['Calibre à remontage automatique équipé de Memovox, fonction signature de la Manufacture inventée en 1950. Cette fonction active un réveil à une heure réglée par le propriétaire.'],
    bottomPic: '/images/918/bottom.png',
  },
  {
    id: 7,
    name: '925/1',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/925/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/925_1.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/925_1.gif',
    middlePics: [{
      name: 'MASTER',
      subName: 'ULTRA THIN MOON',
      cnName: '超薄月相大师系列',
      cnSubName: '腕表',
      tcName: '超薄大師系列',
      tcSubName: '月相腕錶',
      krName: '마스터 울트라 씬 문',
      krSubName: '',
      jpName: 'マスター',
      jpSubName: 'ウルトラスリム・ムーン',
      deName: 'MASTER',
      deSubName: 'ULTRA THIN MOON',
      esName: 'MASTER',
      esSubName: 'ULTRA THIN MOON',
      itName: 'MASTER',
      itSubName: 'ULTRA THIN MOON',
      frName: 'MASTER',
      frSubName: 'ULTRA THIN PHASE DE LUNE',
      src: '/images/925/1.png'
    }, {
      name: 'MASTER',
      subName: 'ULTRA THIN MOON',
      cnName: '超薄月相大师系列',
      cnSubName: '腕表',
      tcName: '超薄大師系列',
      tcSubName: '月相腕錶',
      krName: '마스터 울트라 씬 문',
      krSubName: '',
      jpName: 'マスター',
      jpSubName: 'ウルトラスリム・ムーン',
      deName: 'MASTER ',
      deSubName: 'ULTRA THIN MOON',
      esName: 'MASTER',
      esSubName: 'ULTRA THIN MOON',
      itName: 'MASTER',
      itSubName: 'ULTRA THIN MOON',
      frName: 'MASTER',
      frSubName: 'ULTRA THIN PHASE DE LUNE',
      src: '/images/925/2.png'
    }, {
      name: 'MASTER',
      subName: 'ULTRA THIN MOON',
      cnName: '超薄月相大师系列',
      cnSubName: '腕表',
      tcName: '超薄大師系列',
      tcSubName: '月相腕錶',
      krName: '마스터 울트라 씬 문',
      krSubName: '',
      jpName: 'マスター',
      jpSubName: 'ウルトラスリム・ムーン',
      deName: 'MASTER',
      deSubName: 'ULTRA THIN MOON',
      esName: 'MASTER',
      esSubName: 'ULTRA THIN MOON',
      itName: 'MASTER',
      itSubName: ' ULTRA THIN MOON',
      frName: 'MASTER',
      frSubName: 'ULTRA THIN PHASE DE LUNE',
      src: '/images/925/3.png'
    }],
    overview: ['Automatic', '38 hours', '253', '4.9 mm'],
    functions: ['Date', 'Hour - Minute', 'Moon phases', 'Seconds'],
    description: ['A VERY REFINED AND SOPHISTICATED CALIBRE DISPLAYING A DATE AND A MOONPHASE COMPLICATION.'],
    cnOverview: ['自动上链', '38小时', '253个', '4.9毫米'],
    cnFunctions: ['日期', '时、分显示', '月相', '秒钟'],
    cnDescription: ['一款可以显示日期和月相的复杂功能机芯。'],
    tcOverview: ['自動上鏈', '38 小時', '253', '4.9毫米'],
    tcFunctions: ['日期', '時 - 分', '月相功能', '秒'],
    tcDescription: ['顯示日期和月相複雜功能的非常精緻精密機芯。'],
    krOverview: ['오토매틱', '38', '253', '4.9mm'],
    krFunctions: ['날짜', '시 - 분', '문페이즈', '초'],
    krDescription: ['날짜와 문페이즈 컴플리케이션을 표시하는 매우 세련되고 정교한 칼리버.'],
    jpFunctions: ['日付', '時-分表示', 'ムーンフェーズ ', '秒表示'],
    jpOverview: ['自動巻', '最大38時間', '253', '4.9mm'],
    jpDescription: ['日付とムーンフェーズ複雑機構の表示を可能にする極めて洗練されたキャリバー。'],
    deFunctions: ['Datum', 'Stunde - Minute', 'Mondphasen', 'Sekunden'],
    deOverview: ['Automatik', '38', '253', '4.9 mm'],
    deDescription: ['Ein äußerst raffiniertes und hochwertiges Kaliber mit einer Datums- und einer Mondphasen-Komplikation.'],
    esFunctions: ['Fecha','Horas - Minutos','Fases lunares','Segundos'],
    esOverview: ['Automático','38','253','4.9 mm'],
    esDescription: ['Un calibre muy elegante y sofisticado que muestra una complicación de fecha y una de fase lunar.'],
    itFunctions: ['Data','Ore, minuti','Fasi lunari','Secondi'],
    itOverview: ['Automatico','38','253','4,9 mm'],
    itDescription: ['Un calibro molto raffinato e sofisticato con datario e complicazione delle fasi lunari.'],
    bottomPic: '/images/925/bottom.png',
    frFunctions: ['Date','Heures et minutes','Phases de lune','Secondes'],
    frOverview: ['Remontage automatique','38','253','4,9 mm'],
    frDescription: ['Calibre raffiné et sophistiqué affichant une complication date et phases de lune.'],
    bottomPic: '/images/925/bottom.png',
  },
  {
    id: 8,
    name: '947',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/947/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/947.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/947.gif',
    middlePics: [{
      name: 'REVERSO',
      subName: 'TRIBUTE MINUTE REPEATER',
      cnName: '翻转系列',
      cnSubName: '三问报时腕表',
      tcName: '翻轉系列',
      tcSubName: '三問腕錶',
      krName: '리베르소 트리뷰트 미닛 리피터',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'トリビュート・ミニッツリピーター',
      deName: 'REVERSO',
      deSubName: 'TRIBUTE MINUTE REPEATER',
      esName: 'REVERSO',
      esSubName: 'TRIBUTE MINUTE REPEATER',
      itName: 'REVERSO',
      itSubName: 'TRIBUTE MINUTE REPEATER',
      frName: 'REVERSO',
      frSubName: 'TRIBUTE MINUTE REPEATER',
      src: '/images/947/1.png'
    }, {
      name: 'MASTER',
      subName: 'GRANDE TRADITION CALIBRE 950',
      cnName: '超卓传统大师系列',
      cnSubName: '950型机芯腕表',
      tcName: '超卓傳統大師系列',
      tcSubName: '950機芯',
      krName: '마스터 그랑 트래디션 칼리버 950',
      krSubName: '',
      jpName: 'キャリバー950を搭載したマスター',
      jpSubName: 'グランド・トラディション',
      deName: 'MASTER',
      deSubName: 'GRANDE TRADITION CALIBRE 950',
      esName: 'MASTER',
      esSubName: 'GRANDE TRADITION CALIBRE 950',
      itName: 'MASTER',
      itSubName: 'GRANDE TRADITION CALIBRO 950',
      frName: 'MASTER',
      frSubName: 'GRANDE TRADITION CALIBRE 950',
      src: '/images/947/2.png'
    }, {
      name: 'HYBRIS',
      subName: 'MECHANICA CALIBRE 184',
      cnName: '超卓复杂功能系列',
      cnSubName: '184机芯',
      tcName: '超卓複雜功能系列',
      tcSubName: '184機芯',
      krName: '히브리스 메카니카 칼리버 184',
      krSubName: '',
      jpName: 'ハイブリス',
      jpSubName: 'メカニカ・キャリバー184',
      deName: 'HYBRIS',
      deSubName: 'MECHANICA CALIBRE 184',
      esName: 'HYBRIS',
      esSubName: 'MECHANICA CALIBRE 184',
      itName: 'HYBRIS',
      itSubName: 'MECHANICA CALIBRO 184',
      frName: 'HYBRIS',
      frSubName: 'MECHANICA CALIBRE 184',
      src: '/images/947/3.png'
    }],
    overview: ['Manual', '360 hours', '413', '8.95 mm'],
    functions: ['Hour - Minute', 'Barrel torque indicator', 'Power-reserve', 'Minute Repeater'],
    description: ['A MANUAL WINDING HIGHLY COMPLICATED CALIBRE FEATURING A MINUTE REPEATER AND A 15 DAYS POWER RESERVE.'],
    cnOverview: ['手动上链', '360小时', '413个', '8.95毫米'],
    cnFunctions: ['时、分显示', '发条扭矩显示', '动力储存', '三问报时'],
    cnDescription: ['一款极为复杂的手动上链机芯，特别配备了三问报时功能和15日动力储存。'],
    tcOverview: ['手動上鏈', '360 小時', '413', '8.95毫米'],
    tcFunctions: ['時 - 分', '發條盒扭矩顯示', '動力儲存', '三問報時'],
    tcDescription: ['手動上鏈高度複雜的機芯，配備三問報時和 15 天動力儲存。'],
    krOverview: ['매뉴얼', '360', '413', '8.95mm'],
    krFunctions: ['시 - 분', '배럴 토크 인디케이터', '파워 리저브', '미닛 리피터'],
    krDescription: ['미닛 리피터와 15일 파워 리저브를 갖춘 매우 복잡한 매뉴얼 와인딩 칼리버.'],
    jpFunctions: ['時-分表示', '香箱トルク表示', 'パワーリザーブ', 'ミニッツリピーター'],
    jpOverview: ['手巻', '最大360時間', '413', '8.95mm'],
    jpDescription: ['ミニッツリピーターと15日間のパワーリザーブを備えた、高性能の手巻キャリバー'],
    deFunctions: ['Stunde - Minute', 'Federhaus-Drehmomentanzeige', 'Gangreserve', 'Minutenrepetition'],
    deOverview: ['Handaufzug', '360', '413', '8.95 mm'],
    deDescription: ['Ein hochkompliziertes Handaufzugskaliber mit Minutenrepetition und einer Gangreserve von 15 Tagen.'],
    esFunctions: ['Horas - Minutos','Indicador de resorte de barrilete','Reserva de marcha','Repetidor de minutos'],
    esOverview: ['Manual','360','413','8.95 mm'],
    esDescription: ['Un calibre manual sumamente complicado que incorpora un repetidor de minutos y una reserva de marcha de 15 días.'],
    itFunctions: ['Ore, minuti','Indicatore della coppia del bariletto','Riserva di carica','Ripetizione minuti'],
    itOverview: ['Manuale','360','413','8,95 mm'],
    itDescription: ['Un calibro a carica manuale estremamente complicato dotato di ripetizione minuti e riserva di carica di 15 giorni.'],
    frFunctions: ['Heures et minutes','Indicateur du couple de barillets','Réserve de marche','Répétition Minutes'],
    frOverview: ['Remontage manuel','360','413','8,95 mm'],
    frDescription: ['Calibre compliqué à remontage manuel doté d’une Répétition Minutes et d’une réserve de marche de 15 jours.'],
    bottomPic: '/images/947/bottom.png',
  },
  {
    id: 9,
    name: '966',
    topPic: 'https://d2tvf5266tnt8y.cloudfront.net/en/images/966/top.png',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/966.mp4',
    gif: 'https://d2tvf5266tnt8y.cloudfront.net/en/gif/966.gif',
    middlePics: [{
      name: 'RENDEZ-VOUS',
      subName: 'CLASSIC NIGHT & DAY',
      cnName: '经典约会系列',
      cnSubName: '日夜显示腕表',
      tcName: '約會系列',
      tcSubName: '日夜顯示腕錶',
      krName: '랑데부 클래식 나잇 & 데이',
      krSubName: '',
      jpName: 'ランデヴー',
      jpSubName: 'クラシック・ナイト&デイ',
      deName: 'RENDEZ-VOUS',
      deSubName: 'CLASSIC NIGHT & DAY',
      esName: 'RENDEZ-VOUS',
      esSubName: 'CLASSIC NIGHT & DAY',
      itName: 'RENDEZ-VOUS',
      itSubName: 'CLASSIC NIGHT & DAY',
      frName: 'RENDEZ-VOUS',
      frSubName: 'CLASSIC JOUR & NUIT',
      src: '/images/966/1.png'
    }, {
      name: 'RENDEZ-VOUS',
      subName: 'CLASSIC NIGHT & DAY',
      cnName: '约会系列',
      cnSubName: '日夜显示腕表',
      tcName: '約會系列',
      tcSubName: '日夜顯示腕錶',
      krName: '랑데부 클래식 나잇 & 데이',
      krSubName: '',
      jpName: 'ランデヴー',
      jpSubName: 'クラシック・ナイト&デイ',
      deName: 'RENDEZ-VOUS',
      deSubName: 'CLASSIC NIGHT & DAY',
      esName: 'RENDEZ-VOUS',
      esSubName: 'CLASSIC NIGHT & DAY',
      itName: 'RENDEZ-VOUS',
      itSubName: 'CLASSIC NIGHT & DAY',
      frName: 'RENDEZ-VOUS',
      frSubName: 'CLASSIC JOUR & NUIT',
      src: '/images/966/2.png'
    }, {
      name: 'RENDEZ-VOUS',
      subName: 'CLASSIC MOON',
      cnName: '经典约会系列',
      cnSubName: '月相腕表',
      tcName: '約會系列',
      tcSubName: '月相腕錶',
      krName: '랑데부 클래식 문',
      krSubName: '',
      jpName: 'ランデヴー',
      jpSubName: 'クラシック・ムーン',
      deName: 'RENDEZ-VOUS',
      deSubName: 'CLASSIC MOON',
      esName: 'RENDEZ-VOUS',
      esSubName: 'CLASSIC MOON',
      itName: 'RENDEZ-VOUS',
      itSubName: 'CLASSIC MOON',
      frName: 'RENDEZ-VOUS',
      frSubName: 'CLASSIC PHASE DE LUNE',
      src: '/images/966/3.png'
    }],
    overview: ['Automatic', '42 hours', '214', '4.2 mm'],
    functions: ['Date', 'Hour - Minute', 'Seconds'],
    description: ['A SMALL HIGH-FREQUENCY AUTOMATIC CALIBRE EQUIPPING SMALL-SIZE ROUND WATCHES.'],
    cnOverview: ['自动上链', '42小时', '214个', '4.2毫米'],
    cnFunctions: ['日期', '时、分显示', '秒钟'],
    cnDescription: ['这款小型高频自动上链机芯，常搭载于体积较小的圆形表款。'],
    tcOverview: ['自動上鏈', '42 小時', '214', '4.2毫米'],
    tcFunctions: ['日期', '時 - 分', '秒'],
    tcDescription: ['搭載小型圓形腕錶的小型高頻自動上鏈機芯。'],
    krOverview: ['오토매틱', '42', '214', '4.2mm'],
    krFunctions: ['날짜', '시 - 분', '초'],
    krDescription: ['스몰 사이즈의 원형 워치에 장착된 소형 고주파 오토매틱 칼리버.'],
    jpFunctions: ['日付','時-分表示','秒表示'],
    jpOverview: ['自動巻', '最大42時間', '214', '4.2mm'],
    jpDescription: ['高振動の小型自動巻キャリバーを搭載した小さなラウンド型時計。'],
    deFunctions: ['Datum','Stunde - Minute','Sekunden'],
    deOverview: ['Automatik', '42', '214', '4.2 mm'],
    deDescription: ['Ein kleines Hochfrequenz-Automatikkaliber für kleine, runde Uhren.'],
    esFunctions: ['Fecha','Horas - Minutos','Segundos'],
    esOverview: ['Automático','42','214','4.2 mm'],
    esDescription: ['Un pequeño calibre automático de alta frecuencia que se monta en relojes redondos de tamaño pequeño.'],
    itFunctions: ['Data','Ore, minuti','Secondi'],
    itOverview: ['Automatico','42','214','4,2 mm'],
    itDescription: ['Un piccolo calibro automatico ad alta frequenza utilizzato in orologi rotondi di piccole dimensioni.'],
    frFunctions: ['Date','Heures et minutes','Secondes'],
    frOverview: ['Remontage automatique','42','214','4,2 mm'],
    frDescription: ['Petit calibre à remontage automatique et haute fréquence qui équipe les petites montres rondes.'],
    bottomPic: '/images/966/bottom.png',
  },
  {
    id: 10,
    name: '174',
    topPic: '',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/174.mp4',
    gif: '',
    middlePics: [{
      name: 'REVERSO',
      subName: 'TRIBUTE GYROTOURBILLON',
      cnName: '超卓复杂功能系列',
      cnSubName: '球型陀飞轮翻转腕表',
      tcName: '超卓復雜功能系列',
      tcSubName: '球型陀飛輪翻轉腕表',
      krName: '리베르소 트리뷰트 자이로투르비옹',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'トリビュート・ジャイロトゥールビヨン',
      deName: 'REVERSO',
      deSubName: 'TRIBUTE GYROTOURBILLON',
      esName: 'REVERSO',
      esSubName: 'TRIBUTE GYROTOURBILLON',
      itName: 'REVERSO',
      itSubName: 'TRIBUTE GYROTOURBILLON',
      frName: 'REVERSO',
      frSubName: 'TRIBUTE GYROTOURBILLON',
      src: '/images/174/2.png'
    }, {
      name: 'REVERSO',
      subName: 'TRIBUTE TOURBILLON DUOFACE',
      cnName: '翻转系列',
      cnSubName: '双面双时区陀飞轮腕表',
      tcName: '雙時區翻轉系列',
      tcSubName: '陀飛輪腕錶 ',
      krName: '리베르소 트리뷰트 투르비옹 듀오페이스',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'トリビュート・トゥールビヨン・デュオ',
      deName: 'REVERSO',
      deSubName: 'TRIBUTE TOURBILLON DUOFACE',
      esName: 'REVERSO',
      esSubName: 'TRIBUTE TOURBILLON DUOFACE',
      itName: 'REVERSO',
      itSubName: 'TRIBUTE TOURBILLON DUOFACE',
      frName: 'REVERSO',
      frSubName: 'TRIBUTE TOURBILLON DUOFACE',
      src: '/images/174/3.png'
    }, {
      name: 'REVERSO',
      subName: 'GYROTOURBILLON 2',
      cnName: '超卓复杂功能系列',
      cnSubName: '球型陀飞轮2翻转腕表',
      tcName: '超卓復雜功能系列',
      tcSubName: '球型陀飛輪2翻轉腕表',
      krName: '리베르소 자이로투르비옹 2',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'ジャイロトゥールビヨン2',
      deName: 'REVERSO',
      deSubName: 'GYROTOURBILLON 2',
      esName: 'REVERSO',
      esSubName: 'GYROTOURBILLON 2',
      itName: 'REVERSO',
      itSubName: 'GYROTOURBILLON 2',
      frName: 'REVERSO',
      frSubName: 'GYROTOURBILLON 2',
      src: '/images/174/1.png'
    }],
    overview: ['Manual', '50 hours', '373', '11.1 mm'],
    functions: ['Hour - Minute', 'Movement operating indicator', '24-hour display', 'Seconds', 'Spherical tourbillon'],
    description: ['A multi-axis spherical tourbillon composed of 100 parts and 2 aluminum cages. The outside cage completes a full rotation in 60 seconds and the inside completes it in 18.75 seconds.'],
    cnOverview: ['手动上链', '50小时', '373个', '11.1毫米'],
    cnFunctions: ['时、分显示', '机芯运行指示器 ', '24小时显示 ', '秒钟', '球型陀飞轮'],
    cnDescription: ['由100个零部件和2个铝制笼形框架组成的多轴球型陀飞轮。外笼框架每60秒旋转一周，内笼每18.75秒旋转一周。'],
    tcOverview: ['手動上鏈 ', '50 小時', '373個', '11.1毫米'],
    tcFunctions: ['以小時、分鐘顯示', '機芯運作顯示', '24 小時顯示', '秒', '球形陀飛輪'],
    tcDescription: ['由 100 個零件和 2 個鋁製框架組成的多軸球形陀飛輪。外框會於 60 秒內完成一圈完整的旋轉，內框則會在 18.75 秒內完成。'],
    krOverview: ['매뉴얼', '50', '373', '11.1mm'],
    krFunctions: ['시 - 분', '인디케이터를 움직이는 무브먼트', '24시간 디스플레이', '초', '구형 투르비옹'],
    krDescription: ['100개의 부품과 2개의 알루미늄 케이지로 구성된 다축 구형 투르비옹. 바깥쪽 케이지는 60초 안에, 안쪽 케이지는 18.75초 안에 완전히 회전을 완료합니다.'],
    jpFunctions: ['時-分表示', 'ムーブメント使用表示', '24時間表示', '秒', '球状トゥールビヨン'],
    jpOverview: ['手巻', '最大50時間', '373', '11.1mm'],
    jpDescription: ['球状の多軸トゥールビヨンは100個の部品と2つのアルミニウムケージで構成されています。外側のケージは60秒間で1回転し、内側のケージは18.75秒間に1回転します。'],
    deFunctions: ['Stunde - Minute', 'Gangreserveanzeige', '24-Stunden-Anzeige', 'Sekunden', 'Spährisches Tourbillon'],
    deOverview: ['Handaufzug', '50', '373', '11.1 mm'],
    deDescription: ['Ein mehrachsiges spährisches Tourbillon, das aus 100 Einzelteilen und 2 Aluminiumkäfigen besteht. Der äußere Käfig vollführt in 60 Sekunden eine volle Umdrehung, der innere in 18,75 Sekunden.'],
    esFunctions: ['Horas - Minutos','Indicador de funcionamiento de movimiento','Indicador de 24 horas','Segundos','Tourbillon esférico'],
    esOverview: ['Manual','50','373','11.1 mm'],
    esDescription: ['Un tourbillon esférico de varios ejes compuesto por 100 piezas y 2 cajas de aluminio. La caja exterior realiza una rotación completa en 60 segundos la interior lo hace en 18,75 segundos.'],
    itFunctions: ['Ore, minuti','Indicatore di funzionamento del movimento','Indicatore 24 ore','Secondi','Tourbillon sferico'],
    itOverview: ['Manuale','50','373','11,1 mm'],
    itDescription: ['Un tourbillon sferico multiasse composto da 100 componenti e 2 gabbie in alluminio. La gabbia esterna compie una rotazione completa in 60 secondi e quella interna in 18,75 secondi.'],
    frFunctions: ['Heures et minutes','Indicateur de marche','Affichage 24 heures','Secondes','Tourbillon sphérique'],
    frOverview: ['Remontage manuel','50','373','11,1 mm'],
    frDescription: ['Tourbillon sphérique multi-axes composé de 100 composants et de deux cages en aluminium. La cage externe effectue une rotation complète en 60 secondes, et l’interne en 18,75 secondes.'],
    bottomPic: '/images/174/bottom.png',
  },
  {
    id: 11,
    name: '853A',
    topPic: '',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/853A.mp4',
    gif: '',
    middlePics: [{
      name: 'REVERSO',
      subName: 'TRIBUTE DUOFACE MOON',
      cnName: '双面翻转',
      cnSubName: '月相腕表',
      tcName: '翻轉系列',
      tcSubName: '月相腕錶',
      krName: '리베르소 트리뷰트 듀오페이스 문',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'トリビュート・デュオ・ムーン',
      deName: 'REVERSO',
      deSubName: 'TRIBUTE DUOFACE MOON',
      esName: 'REVERSO',
      esSubName: 'TRIBUTE DUOFACE MOON',
      itName: 'REVERSO',
      itSubName: 'TRIBUTE DUOFACE MOON',
      frName: 'REVERSO',
      frSubName: 'TRIBUTE DUOFACE PHASE DE LUNE',
      src: '/images/853a/1.png'
    }, {
      name: 'REVERSO',
      subName: 'TRIBUTE DUOFACE CALENDAR',
      cnName: '翻转系列',
      cnSubName: '日历腕表',
      tcName: '翻轉系列',
      tcSubName: '雙時區日曆腕錶',
      krName: '리베르소 트리뷰트 듀오페이스 캘린더',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'トリビュート・デュオ・カレンダー',
      deName: 'REVERSO',
      deSubName: 'TRIBUTE DUOFACE CALENDAR',
      esName: 'REVERSO',
      esSubName: 'TRIBUTE DUOFACE CALENDAR',
      itName: 'REVERSO',
      itSubName: 'TRIBUTE DUOFACE CALENDAR',
      frName: 'REVERSO',
      frSubName: 'TRIBUTE DUOFACE CALENDRIER',
      src: '/images/853a/2.png'
    }, {
      name: 'MASTER',
      subName: 'ULTRA THIN MOON',
      cnName: '超薄大师系列',
      cnSubName: '月相腕表',
      tcName: '超薄大師系列',
      tcSubName: '腕錶 ',
      krName: '마스터 울트라 씬 문',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'ウルトラスリム・ムーン',
      deName: 'MASTER',
      deSubName: 'ULTRA THIN MOON',
      esName: 'MASTER',
      esSubName: 'ULTRA THIN MOON',
      itName: 'MASTER',
      itSubName: 'ULTRA THIN MOON',
      frName: 'MASTER',
      frSubName: 'ULTRA THIN PHASE DE LUNE',
      src: '/images/853a/3.png'
    }],
    overview: ['Manual', '42 hours', '223', '5.15 mm'],
    functions: ['Date', 'Second time-zone', 'Hour - Minute', 'Day/Night indicator', 'Moon phases'],
    description: ['Inspired by the ingenious Duoface complication and completing the dual time zone functions on both sides, the Calibre 853A adds a Moon phase indicator on the front dial.'],
    cnOverview: ['手动上链', '42小时', '223个', '5.15毫米'],
    cnFunctions: ['日期', '第二时区 ', '时、分显示 ', '日/夜标志', '月相'],
    cnDescription: ['积家853A型机芯在正面表盘上增加了月相指示器，灵感源于巧妙的Duoface复杂装置，与两侧的双时区功能形成呼应。'],
    tcOverview: ['手動上鏈 ', '42 小時', '223個', '5.15毫米'],
    tcFunctions: ['日期', '第二時區時間', '以小時、分鐘顯示', '晝/夜顯示', '月相'],
    tcDescription: ['靈感源自巧精細巧妙的 Duoface 複雜功能，兩邊都備有雙時區功能，853A 機芯在前錶面的錶盤上增加了月相顯示。'],
    krOverview: ['매뉴얼', '42', '223', '5.15mm'],
    krFunctions: ['날짜', '세컨드 타임존', '시 - 분', '낮/밤 인디케이터', '문페이즈'],
    krDescription: ['독창적인 듀오페이스 컴플리케이션에서 영감을 얻어 양면의 듀얼 타임존 기능을 완성한 칼리버 853A는 앞면 다이얼에 문페이즈 인디케이터를 추가했습니다.'],
    jpFunctions: ['日付', '第2時間帯', '時-分表示', 'デイ/ナイト表示', 'ムーンフェーズ'],
    jpOverview: ['手巻', '最大42時間', '223', '5.15mm'],
    jpDescription: ['精巧なデュオフェイスの複雑機構からインスパイアされ、両サイドのダイヤルに2つのタイムゾーンを備えたキャリバー853Aは、さらに表ダイヤルにムーンフェーズを搭載しています。'],
    deFunctions: ['Datum', 'Zweite Zeitzone', 'Stunde - Minute', 'Tag/Nachtanzeige', 'Mondphasen'],
    deOverview: ['Handaufzug', '42', '223', '5.15 mm'],
    deDescription: ['Das Kaliber 853A ist von der genialen Duoface-Komplikation inspiriert und verfügt über eine Mondphasenanzeige auf dem vorderen Zifferblatt, die die doppelten Zeitzonenanzeigen auf beiden Seiten ergänzt.'],
    esFunctions: ['Fecha','Segundo huso horario','Horas - Minutos','Indicador día/noche','Fases lunares'],
    esOverview: ['Manual','42','223','5.15 mm'],
    esDescription: ['Inspirado en la ingeniosa complicación Duoface y completando las funciones de zona horaria doble en ambos lados, el calibre 853A añade un indicador de fases lunares en la esfera frontal.'],
    itFunctions: ['Data','Secondo fuso orario','Ore, minuti','Indicatore giorno/notte','Fasi lunari'],
    itOverview: ['Manuale','42','223','5,15 mm'],
    itDescription: ['Ispirandosi all’ingegnosa complicazione Duoface e completando le funzioni del doppio fuso orario su entrambi i lati, il Calibro 853A aggiunge un indicatore delle fasi lunari sul primo quadrante.'],
    frFunctions: ['Date','Second fuseau horaire','Heures et minutes','Indicateur jour/nuit','Phases de lune'],
    frOverview: ['Remontage manuel','42','223','5,15 mm'],
    frDescription: ['Inspiré de la complication ingénieuse Duoface, le Calibre 853A gère un double fuseau horaire sur les deux côtés. Il ajoute également un indicateur de phases de lune sur le cadran avant.'],
    bottomPic: '/images/853a/bottom.png',
  },
  {
    id: 12,
    name: '967B',
    topPic: '',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/967B.mp4',
    gif: '',
    middlePics: [{
      name: 'GRANDE REVERSO',
      subName: 'NIGHT & DAY',
      cnName: '超卓复杂功能系列',
      cnSubName: '日夜显示翻转腕表',
      tcName: '日夜翻轉系列',
      tcSubName: '腕錶',
      krName: '그랑 리베르소 나잇 & 데이',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'レベルソ・ナイト&デイ',
      deName: 'GRANDE',
      deSubName: 'REVERSO NIGHT & DAY',
      esName: 'GRANDE',
      esSubName: 'REVERSO NIGHT & DAY ',
      itName: 'GRANDE',
      itSubName: 'REVERSO NIGHT & DAY',
      frName: 'GRANDE',
      frSubName: 'REVERSO JOUR & NUIT',
      src: '/images/967b/1.png'
    }, {
      name: 'REVERSO',
      subName: 'CLASSIC DUETTO',
      cnName: '双面翻转系列',
      cnSubName: '腕表',
      tcName: '翻轉系列',
      tcSubName: '經典雙面腕錶',
      krName: '리베르소 클래식 듀에토',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'クラシック・デュエット',
      deName: 'REVERSO',
      deSubName: 'CLASSIC DUETTO',
      esName: 'REVERSO',
      esSubName: 'CLASSIC DUETTO',
      itName: 'REVERSO',
      itSubName: 'CLASSIC DUETTO',
      frName: 'REVERSO',
      frSubName: 'CLASSIC DUETTO',
      src: '/images/967b/2.png'
    }, {
      name: 'REVERSO',
      subName: 'CLASSIC DUETTO',
      cnName: '双面翻转系列',
      cnSubName: '腕表',
      tcName: '翻轉系列',
      tcSubName: '經典雙面腕錶 ',
      krName: '리베르소 클래식 듀에토',
      krSubName: '',
      jpName: 'レベルソ',
      jpSubName: 'クラシック・デュエット',
      deName: 'REVERSO',
      deSubName: 'CLASSIC DUETTO',
      esName: 'REVERSO',
      esSubName: 'CLASSIC DUETTO',
      itName: 'REVERSO',
      itSubName: 'CLASSIC DUETTO',
      frName: 'REVERSO',
      frSubName: 'CLASSIC DUETTO',
      src: '/images/967b/3.png'
    }],
    overview: ['Automatic', '42 hours', '203', '4.05 mm'],
    functions: ['Hour - Minute', '24-hour display'],
    description: ['A high-frequency automatic calibre used in Reverso. A specific calibre developed to fit in the rectangular swiveling case.'],
    cnOverview: ['自动机芯', '42小时', '203个', '4.05毫米'],
    cnFunctions: ['时、分显示', '24小时显示'],
    cnDescription: ['Reverso翻转系列腕表使用的高频自动机芯。专为长方形翻转表壳设计的机芯。'],
    tcOverview: ['自動上鏈 ', '42 小時', '203個', '4.05毫米'],
    tcFunctions: ['以小時、分鐘顯示', '24 小時顯示'],
    tcDescription: ['用於 Reverso 的高振頻自動機芯。專為安裝在長方形旋轉錶殼包裝中而設計的特定機芯。'],
    krOverview: ['오토매틱', '42', '203', '4.05mm'],
    krFunctions: ['시 - 분', '24시간 디스플레이'],
    krDescription: ['리베르소에 사용된 고주파 오토매틱 칼리버. 직사각형 회전 케이스에 맞도록 개발된 특별한 칼리버.'],
    jpFunctions: [ '時-分表示', '24時間表示'],
    jpOverview: ['自動巻', '最大42時間', '203', '4.05mm'],
    jpDescription: ['レベルソに搭載されている高性能自動巻キャリバー。長方形の回転式ケースに収めるために、特別に開発されたキャリバー。'],
    deFunctions: ['Stunde – Minute', '24-Stunden-Anzeige'],
    deOverview: ['Automatik', '42', '203', '4.05 mm'],
    deDescription: ['Ein Hochfrequenz-Automatikkaliber, das die Reverso ausstattet. Speziell für rechteckige Wendegehäuse entwickeltes Kaliber.'],
    esFunctions: ['Horas - Minutos','Indicador de 24 horas'],
    esOverview: ['Automático','42','203','4.05 mm'],
    esDescription: ['Un calibre automático de alta frecuencia usado en el Reverso. Un calibre desarrollado específicamente para ajustarse a la caja rectangular giratoria.'],
    itFunctions: ['Ore, minuti','Indicatore 24 ore'],
    itOverview: ['Automatico','42','203','4,05 mm'],
    itDescription: ['Un calibro automatico ad alta frequenza utilizzato nel Reverso. Un calibro specificamente sviluppato per adattarsi all’iconica cassa rettangolare ribaltabile.'],
    frFunctions: ['Heures et minutes','Affichage 24 heures'],
    frOverview: ['Remontage automatique','42','203','4,05 mm'],
    frDescription: ['Calibre haute fréquence à remontage automatique utilisé dans la Reverso. Calibre spécifiquement développé pour s’adapter au boîtier pivotant rectangulaire.'],
    bottomPic: '/images/967b/bottom.png',
  },
  {
    id:13,
    name: '987',
    topPic: '',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/987.mp4',
    gif: '',
    middlePics: [{
      name: 'Master',
      subName: 'Grande Tradition Tourbillon à Quantième Perpétuel',
      cnName: '超卓传统',
      cnSubName: '万年历大师系列腕表',
      tcName: '超卓傳統',
      tcSubName: '大師系列萬年曆陀飛輪腕錶',
      krName: '마스터 그랑 ',
      krSubName: '트래디션 투르비옹 아 퀀템 퍼페추얼',
      jpName: 'マスター',
      jpSubName: 'グランド・トラディション・トゥールビヨン・カンティエーム・パーペチュアルカレンダー ',
      deName: 'Master',
      deSubName: 'Grande Tradition Tourbillon à Quantième Perpétuel',
      esName: 'Master',
      esSubName: 'Grande Tradition Tourbillon à Quantième Perpétuel',
      itName: 'Master',
      itSubName: 'Grande Tradition Tourbillon à Quantième Perpétuel',
      frName: 'Master',
      frSubName: 'Grande Tradition Tourbillon à Quantième Perpétuel',
      src: '/images/987/2.png',
    }, {
      name: 'Master',
      subName: 'Grande Tradition Tourbillon à Quantième Perpétuel',
      cnName: '超卓传统',
      cnSubName: '万年历大师系列腕表',
      tcName: '超卓傳統',
      tcSubName: '大師系列萬年曆陀飛輪腕錶',
      krName: '마스터 그랑',
      krSubName: '트래디션 투르비옹 아 퀀템 퍼페추얼',
      jpName: 'マスター',
      jpSubName: 'グランド・トラディション・トゥールビヨン・カンティエーム・パーペチュアルカレンダー',
      deName: 'Master',
      deSubName: 'Grande Tradition Tourbillon à Quantième Perpétuel',
      esName: 'Master',
      esSubName: 'Grande Tradition Tourbillon à Quantième Perpétuel',
      itName: 'Master',
      itSubName: 'Grande Tradition Tourbillon à Quantième Perpétuel',
      frName: 'Master',
      frSubName: 'Grande Tradition Tourbillon à Quantième Perpétuel ',
      src: '/images/987/1.png',
    }, {
      name: 'Master',
      subName: 'Grande Tradition Tourbillon Céleste',
      cnName: '超卓传统',
      cnSubName: '大师系列陀飞轮星空腕表',
      tcName: '超卓傳統',
      tcSubName: '大師系列陀飛輪星空腕錶',
      krName: '마스터 그랑',
      krSubName: '트래디션 투르비옹 셀레스트',
      jpName: 'マスター',
      jpSubName: 'グランド・トラディション・トゥールビヨン・セレスト',
      deName: 'Master',
      deSubName: 'Grande Tradition Tourbillon Céleste',
      esName: 'Master',
      esSubName: 'Grande Tradition Tourbillon Céleste',
      itName: 'Master',
      itSubName: 'Grande Tradition Tourbillon Céleste',
      frName: 'Master',
      frSubName: 'Grande Tradition Tourbillon Céleste',
      src: '/images/987/3.png',
    }],
    overview: ['Automatic', '48 hours', '401', '8.15 mm'],
    functions: ['Perpetual calendar', 'Hour - Minute', 'Moon phases', 'Seconds', 'Tourbillon'],
    description: ['A highly complicated automatic movement with tourbillon escapement and perpetual calendar'],
    cnOverview: ['自动机芯', '48小时', '401', '8.15毫米'],
    cnFunctions: ['万年历', '时、分显示', '月相功能', '秒钟', '陀飞轮'],
    cnDescription: ['搭载陀飞轮擒纵装置和万年历复杂功能的高复杂自动机芯'],
    tcOverview: ['自動上鏈', '48小時', '401個', '8.15 毫米'],
    tcFunctions: ['萬年曆', '以小時、分鐘顯示', '月相', '秒', '陀飛輪'],
    tcDescription: ['配備陀飛輪擒縱輪和萬年曆的高複雜自動機芯'],
    krOverview: ['오토매틱', '48', '401', '8.15 mm'],
    krFunctions: ['기능', '퍼페추얼 캘린더', '시 - 분', '문페이즈', '초', '투르비옹'],
    krDescription: ['투르비옹 이스케이프먼트와 퍼페추얼 캘린더가 장착된 아주 정교한 오토매틱 무브먼트'],
    jpFunctions: ['永久カレンダー ', '時 - 分', 'ムーンフェーズ', '秒', 'トゥールビヨン'],
    jpOverview: ['自動巻', '最大48時間', '401', '8.15 mm'],
    jpDescription: ['トゥールビヨン脱進機と永久カレンダーを備えた非常に複雑な自動巻ムーブメント'],
    deFunctions: ['Ewiger Kalender', 'Stunde – Minute', 'Mondphasen', 'Sekunden', 'Tourbillon'],
    deOverview: ['Automatik', '48', '401', '8.15 mm'],
    deDescription: ['Ein äußerst kompliziertes Automatikwerk mit Tourbillonhemmung und ewigem Kalender'],
    esFunctions: ['Calendario perpetuo', 'Horas - Minutos', 'Fases lunares', 'Segundos', 'Un Tourbillon'],
    esOverview: ['Automático', '48', '401', '8.15 mm'],
    esDescription: ['Un movimiento automático sumamente complicado con un escape de tourbillon y un calendario perpetuo'],
    itFunctions: ['Calendario perpetuo', 'Ore, minuti', 'Fasi lunari', 'Secondi', 'Tourbillon'],
    itOverview: ['Automatico', '48', '401', '8,15 mm'],
    itDescription: ['Un movimento automatico estremamente complicato con scappamento a tourbillon e calendario perpetuo'],
    frFunctions: ['Calendrier perpétuel', 'Heures et minutes', 'Phases de lune', 'Secondes', 'Tourbillon'],
    frOverview: ['Remontage automatique', '48', '401', '8,15 mm'],
    frDescription: ['Un mouvement automatique extrêmement complexe doté d’un échappement à tourbillon et d’un calendrier perpétuel.'],
    bottomPic: '/images/987/bottom.png',
  },
  {
    id:14,
    name: '383',
    topPic: '',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/383.mp4',
    gif: '',
    middlePics: [{
      name: 'Duomètre',
      subName: 'Unique Travel Time',
      cnName: '双翼',
      cnSubName: '寰宇旅行时间腕表',
      tcName: '雙翼系列',
      tcSubName: '寰宇旅行時間腕錶',
      krName: '듀오미터',
      krSubName: '유니크 트래블 타임',
      jpName: 'デュオメトル',
      jpSubName: 'ユニーク・トラベルタイム',
      deName: 'Duomètre',
      deSubName: 'Unique Travel Time',
      esName: 'Duomètre',
      esSubName: 'Unique Travel Time',
      itName: 'Duomètre',
      itSubName: 'Unique Travel Time',
      frName: 'Duomètre',
      frSubName: 'Unique Travel Time',
      src: '/images/383/1.png',
    }, {
      name: 'Duomètre',
      subName: 'Chronographe',
      cnName: '双翼',
      cnSubName: '计时腕表',
      tcName: '雙翼系列',
      tcSubName: '計時腕錶',
      krName: '듀오미터',
      krSubName: '크로노그래프',
      jpName: 'デュオメトル',
      jpSubName: 'クロノグラフ',
      deName: 'Duomètre',
      deSubName: 'Chronographe',
      esName: 'Duomètre',
      esSubName: 'Chronographe',
      itName: 'Duomètre',
      itSubName: 'Chronographe',
      frName: 'Duomètre',
      frSubName: 'Chronographe',
      src: '/images/383/2.png',
    }, {
      name: 'Reverso',
      subName: 'Tribute Calendar',
      cnName: '翻转系列',
      cnSubName: '全历腕表',
      tcName: '翻轉系列',
      tcSubName: '日曆腕錶',
      krName: '리베르소',
      krSubName: '트리뷰트 캘린더',
      jpName: 'レベルソ',
      jpSubName: 'トリビュート・カレンダー',
      deName: 'Reverso',
      deSubName: 'Tribute Calendar',
      esName: 'Reverso',
      esSubName: 'Tribute Calendar',
      itName: 'Reverso',
      itSubName: 'Tribute Calendar',
      frName: 'Reverso',
      frSubName: 'Tribute Calendar',
      src: '/images/383/4.png',
    }],
    overview: ['Manual', '50 hours', '498', '7.25 mm'],
    functions: ['Second time-zone', 'Hour - Minute', 'Jumping hour', 'Day/Night indicator', 'Map of the world', 'Seconds', 'World time', 'Twin Power-Reserve'],
    description: ['Calibre Jaeger-LeCoultre equipped with the Duometre concept accompaning elegantly through every continent with an adjustment possible for non-integer time zones countries.'],
    cnOverview: ['手动上链机芯', '50小时', '498', '7.25毫米'],
    cnFunctions: ['第二时区', '时、分显示', '跳时显示', '日/夜标志', '世界地图', '秒钟', '世界时间', '双动力储存'],
    cnDescription: ['积家机芯采用Duometre双翼设计理念，优雅穿行于世界各地，并可针对非整数时区的国家及地区进行调校。'],
    tcOverview: ['手動上鏈', '50小時', '498個', '7.25 毫米'],
    tcFunctions: ['第二時區時間', '以小時、分鐘顯示', '跳時', '晝/夜顯示', '世界地圖', '秒', '世界時間', '雙動力儲存'],
    tcDescription: ['積家機芯搭載 Duomètre 雙翼系列腕錶概念，優雅穿越各大洲，並可針對非整數時區國家進行調整。'],
    krOverview: ['매뉴얼', '50', '498', '7.25mm'],
    krFunctions: ['세컨드 타임존', '시 - 분', '점핑 아워', '낮/밤 인디케이터', '세계 지도', '초', '월드타임', '트윈 파워 리저브'],
    krDescription: ['비표준 타임존 국가에 대해 시간 조정이 가능하여 세계 어디에서나 우아한 동반자가 될 듀오미터 콘셉트를 갖춘 칼리버 예거 르쿨트르.'],
    jpFunctions: ['第2時間帯', '時 - 分', 'ジャンピングアワー', 'デイ/ナイト表示', '世界地図', '秒', 'ワールドタイム表示', 'ツインパワーリザーブ'],
    jpOverview: ['手巻', '最大50時間', '498', '7.25mm'],
    jpDescription: ['非整数タイムゾーンの国も含め、世界のどんな場所の時刻にもエレガントに調節できるデュオメトル・コンセプトを採用したジャガー・ルクルト製キャリバー。'],
    deFunctions: ['Zweite Zeitzone', 'Stunde – Minute', 'Springende Stundenanzeige', 'Tag/Nachtanzeige', 'Weltkarte', 'Sekunden', 'Weltzeit', 'Doppelte Gangreserve'],
    deOverview: ['Handaufzug', '50', '498', '7.25 mm'],
    deDescription: ['Das Jaeger-LeCoultre Kaliber, ausgestattet mit dem Duometre-Konzept, begleitet Sie elegant durch alle Kontinente. Eine Anpassung an Länder mit nicht-ganzzahligen Zeitzonen ist möglich.'],
    esFunctions: ['Segundo huso horario', 'Horas - Minutos', 'Hora saltante', 'Indicador día/noche', 'Mapa del mundo', 'Segundos', 'Hora mundial', 'Reserva de marcha doble'],
    esOverview: ['Manual', '50', '498', '7.25 mm'],
    esDescription: ['Calibre Jaeger-LeCoultre equipado con el concepto Duomètre, que va acompañando elegantemente por cada continente, con un ajuste posible para países con zonas horarias no integrales.'],
    itFunctions: ['Secondo fuso orario', 'Ore, minuti', 'Ora saltante', 'Indicatore giorno/notte', 'Mappa del mondo', 'Secondi', 'Ora mondiale', 'Riserva di carica doppia'],
    itOverview: ['Manuale', '50', '498', '7,25 mm'],
    itDescription: ['Calibro Jaeger-LeCoultre dotato del concetto Duomètre che accompagna elegantemente attraverso tutti i continenti con regolazione possibile per i Paesi con fusi orari non interi.'],
    frFunctions: ['Second fuseau horaire', 'Heures et minutes', 'Heure sautante', 'Indicateur jour/nuit', 'Carte du monde', 'Secondes', 'Heure universelle', 'Double réserve de marche'],
    frOverview: ['Remontage manuel', '50', '498', '7,25 mm'],
    frDescription: ['Calibre Jaeger-LeCoultre équipé du concept Duomètre, avec un ajustement possible pour les pays ayant un fuseau horaire décalé d’un nombre d’heures non entier.'],
    bottomPic: '/images/383/bottom.png',
  },
  {
    id:15,
    name: '381',
    topPic: '',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/381.mp4',
    gif: '',
    middlePics: [{
      name: 'Duomètre',
      subName: 'Quantième Lunaire',
      cnName: '双翼系列',
      cnSubName: '月相日历腕表',
      tcName: '雙翼系列',
      tcSubName: '月相日曆腕錶',
      krName: '듀오미터',
      krSubName: '퀀템 루너',
      jpName: 'デュオメトル',
      jpSubName: 'カンティエーム・ルネール',
      deName: 'Duomètre',
      deSubName: 'Quantième Lunaire',
      esName: 'Duomètre',
      esSubName: 'Quantième Lunaire',
      itName: 'Duomètre',
      itSubName: 'Quantième Lunaire',
      frName: 'Duomètre',
      frSubName: 'Quantième Lunaire',
      src: '/images/381/1.png',
    }, {
      name: 'Duomètre',
      subName: 'Chronographe',
      cnName: '双翼',
      cnSubName: '计时腕表',
      tcName: '雙翼系列',
      tcSubName: '計時腕錶',
      krName: '듀오미터',
      krSubName: '크로노그래프',
      jpName: 'デュオメトル',
      jpSubName: 'クロノグラフ',
      deName: 'Duomètre',
      deSubName: 'Chronographe',
      esName: 'Duomètre',
      esSubName: 'Chronographe',
      itName: 'Duomètre',
      itSubName: 'Chronographe',
      frName: 'Duomètre',
      frSubName: 'Chronographe',
      src: '/images/381/2.png',
    }, {
      name: 'Master',
      subName: 'Ultra Thin Moon',
      cnName: '超薄大师系列',
      cnSubName: '月相腕表',
      tcName: '超薄大師系列',
      tcSubName: '月相腕錶',
      krName: '마스터',
      krSubName: '울트라 씬 문',
      jpName: 'マスター',
      jpSubName: 'ウルトラスリム・ムーン',
      deName: 'Master',
      deSubName: 'Ultra Thin Moon',
      esName: 'Master',
      esSubName: 'Ultra Thin Moon',
      itName: 'Master',
      itSubName: 'Ultra Thin Moon',
      frName: 'Master',
      frSubName: 'Ultra Thin Moon',
      src: '/images/381/3.png',
    }],
    overview: ['Manual', '50 hours', '374', '7.25 mm'],
    functions: ['Date', 'Hour - Minute', 'Moon phases', 'Seconds', 'Jumping seconds', 'Twin Power-Reserve'],
    description: ['Calibre Jaeger-LeCoultre equipped with the Duometre concept providing the date and the age of the moon for the Northern and Southern hemisphere.'],
    cnOverview: ['手动上链机芯', '50小时', '374', '7.25毫米'],
    cnFunctions: ['日期', '时、分显示', '月相功能', '秒钟', '跳秒', '双动力储存'],
    cnDescription: ['积家机芯采用Duometre双翼设计理念，提供南北半球月相及月龄显示。'],
    tcOverview: ['手動上鏈', '50小時', '374個', '7.25毫米'],
    tcFunctions: ['日期', '以小時、分鐘顯示', '月相', '秒', '跳秒', '雙動力儲存'],
    tcDescription: ['積家機芯搭載 Duomètre 雙翼系列腕錶概念，顯示南北半球的日期和月齡。'],
    krOverview: ['매뉴얼', '50', '374', '7.25mm'],
    krFunctions: ['날짜', '시 - 분', '문페이즈', '초', '점핑 세컨즈', '트윈 파워 리저브'],
    krDescription: ['북반구와 남반구의 날짜와 월령을 제공하는 듀오미터 콘셉트를 갖춘 칼리버 예거 르쿨트르. '],
    jpFunctions: ['日付', '時 - 分', 'ムーンフェーズ', '秒', 'ジャンピングセコンド', 'ツインパワーリザーブ'],
    jpOverview: ['手巻', '最大50時間', '374', '7.25mm'],
    jpDescription: ['北半球と南半球の月齢と月相を表示するデュオメトル・コンセプトを採用したジャガー・ルクルト製キャリバー。'],
    deFunctions: ['Datum', 'Stunde – Minute', 'Mondphasen', 'Sekunden', 'Springende Sekunde', 'Doppelte Gangreserve'],
    deOverview: ['Handaufzug', '50', '374', '7.25 mm'],
    deDescription: ['Das Kaliber Jaeger-LeCoultre ist mit dem Duometre-Konzept ausgestattet und zeigt das Datum sowie das Alter des Mondes für die nördliche und südliche Hemisphäre an.'],
    esFunctions: ['Fecha', 'Horas - Minutos', 'Fases lunares', 'Segundos', 'Segundos saltantes', 'Reserva de marcha doble'],
    esOverview: ['Manual', '50', '374', '7.25 mm'],
    esDescription: ['Calibre Jaeger-LeCoultre equipado con el concepto Duomètre, que indica la fecha y la fase lunar en el hemisferio norte y en el sur.'],
    itFunctions: ['Data', 'Ore, minuti', 'Fasi lunari', 'Secondi', 'Secondi saltanti', 'Riserva di carica doppia'],
    itOverview: ['Manuale', '50', '374', '7,25 mm'],
    itDescription: ['Calibro Jaeger-LeCoultre dotato del concetto Duomètre che fornisce la data e l’età della luna per l’emisfero settentrionale e meridionale.'],
    frFunctions: ['Date', 'Heures et minutes', 'Phases de lune', 'Secondes', 'Secondes sautantes', 'Double réserve de marche'],
    frOverview: ['Remontage manuel', '50', '374', '7,25 mm'],
    frDescription: ['Calibre Jaeger-LeCoultre équipé du concept Duomètre indiquant la date et le cycle de la lune pour les hémisphères Nord et Sud.'],
    bottomPic: '/images/381/bottom.png',
  },
  {
    id:16,
    name: '939AA',
    topPic: '',
    video: 'https://d2tvf5266tnt8y.cloudfront.net/en/videos/939AA.mp4',
    gif: '',
    middlePics: [{
      name: 'Master',
      subName: 'Control Geographic',
      cnName: '大师系列',
      cnSubName: '地理学家腕表',
      tcName: '大師系列',
      tcSubName: '地理學家腕錶',
      krName: '마스터',
      krSubName: '컨트롤 지오그래픽',
      jpName: 'マスター',
      jpSubName: 'コントロール・ジオグラフィーク',
      deName: 'Master',
      deSubName: 'Control Geographic',
      esName: 'Master',
      esSubName: 'Control Geographic',
      itName: 'Master',
      itSubName: 'Control Geographic',
      frName: 'Master',
      frSubName: 'Control Geographic',
      src: '/images/939aa/2.png',
    }, {
      name: 'Reverso',
      subName: 'Tribute Duoface Small Seconds',
      cnName: '翻转系列',
      cnSubName: '小秒针腕表',
      tcName: '翻轉系列',
      tcSubName: '雙時區小秒針腕錶',
      krName: '리베르소',
      krSubName: '트리뷰트 듀오페이스 스몰 세컨즈',
      jpName: 'レベルソ',
      jpSubName: 'トリビュート・デュオ・スモールセコンド',
      deName: 'Reverso',
      deSubName: 'Tribute Duoface Small Seconds',
      esName: 'Reverso',
      esSubName: 'Tribute Duoface Small Seconds',
      itName: 'Reverso',
      itSubName: 'Tribute Duoface Small Seconds',
      frName: 'Reverso',
      frSubName: 'Tribute Duoface Small Seconds',
      src: '/images/939aa/4.png',
    }, {
      name: 'Duomètre',
      subName: 'Unique Travel Time',
      cnName: '双翼',
      cnSubName: '寰宇旅行时间腕表',
      tcName: '雙翼系列',
      tcSubName: '寰宇旅行時間腕錶',
      krName: '듀오미터',
      krSubName: '유니크 트래블 타임',
      jpName: 'デュオメトル',
      jpSubName: 'ユニーク・トラベルタイム',
      deName: 'Duomètre',
      deSubName: 'Unique Travel Time',
      esName: 'Duomètre',
      esSubName: 'Unique Travel Time',
      itName: 'Duomètre',
      itSubName: 'Unique Travel Time',
      frName: 'Duomètre',
      frSubName: 'Unique Travel Time',
      src: '/images/939aa/5.png',
    }],
    overview: ['Automatic', '70 hours', '295', '4.9 mm'],
    functions: ['Date', 'Second time-zone', 'Hour - Minute', '24-hour display ', 'Power-reserve', 'Seconds', '24 time-zones'],
    description: ['The legendary Geographic caliber allows travelers to roam the world, simultaneously reading the time at home and abroad.'],
    cnOverview: ['自动机芯', '70小时', '295', '4.9毫米'],
    cnFunctions: ['日期', '第二时区', '时、分显示', '24小时显示', '动力储存', '秒钟', '24个时区'],
    cnDescription: ['极富传奇色彩的Geographic地理学家机芯可令旅行家轻松读取国内外时间，开启寰球漫游之旅。'],
    tcOverview: ['自動上鏈', '70小時', '295個', '4.9毫米'],
    tcFunctions: ['日期', '第二時區時間', '以小時、分鐘顯示', '24 小時顯示', '動力儲存', '秒', '24 個時區'],
    tcDescription: ['傳奇的地理機芯讓旅遊人士輕鬆遨遊世界，亦可同時顯示國內和國外在地的時間。'],
    krOverview: ['오토매틱', '70', '295', '4.9mm'],
    krFunctions: ['날짜', '세컨드 타임존', '시 - 분', '24시간 디스플레이', '파워 리저브', '초', '24시간 타임존'],
    krDescription: ['상징적인 지오그래픽 칼리버는 여행자가 전 세계를 돌아다니며 국내외 시간을 동시에 확인할 수 있습니다.'],
    jpFunctions: ['日付', '第2時間帯', '時 - 分', '24時間表示', 'パワーリザーブ', '秒', '24タイムゾーン'],
    jpOverview: ['自動巻', '最大70時間', '295', '4.9mm'],
    jpDescription: ['この伝説的なジオグラフィークのキャリバーは、世界中を旅する方が自国時間と現地時間の両方を瞬時に把握できるよう作られています。'],
    deFunctions: ['Datum', 'Zweite Zeitzone', 'Stunde – Minute', '24-Stunden-Anzeige', 'Gangreserve', 'Sekunden', '24 Zeitzonen'],
    deOverview: ['Automatik', '70', '295', '4.9 mm'],
    deDescription: ['Mit dem legendären Geographic-Kaliber können Sie um die Welt reisen und sowohl die Zeit zu Hause als auch die Ortszeit ablesen.'],
    esFunctions: ['Fecha', 'Segundo huso horario', 'Horas - Minutos', 'Indicador de 24 horas', 'Reserva de marcha', 'Segundos', '24 zonas horarias'],
    esOverview: ['Automático', '70', '295', '4.9 mm'],
    esDescription: ['El legendario calibre Geographic permite a los viajeros recorrer el mundo sin dejar de saber la hora en casa y en el extranjero.'],
    itFunctions: ['Data', 'Secondo fuso orario', 'Ore, minuti', 'Indicatore 24 ore', 'Riserva di carica', 'Secondi', '24 fusi orari'],
    itOverview: ['Automatico', '70', '295', '4,9 mm'],
    itDescription: ['Il leggendario calibro Geographic permette ai viaggiatori di esplorare il mondo, leggendo contemporaneamente l’ora in patria e all’estero.'],
    frFunctions: ['Date', 'Second fuseau horaire', 'Heures et minutes', 'Affichage 24 heures', 'Réserve de marche', 'Secondes', '24 fuseaux horaires'],
    frOverview: ['Remontage automatique', '70', '295', '4,9 mm'],
    frDescription: ['Le légendaire calibre Geographic permet au voyageur de parcourir le monde, tout en connaissant son heure locale et son heure de référence.'],
    bottomPic: '/images/939aa/bottom.png',
  },
  
]

export default list