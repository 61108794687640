<template>
  <!-- <div class="container" :class="lan==='en' ? 'en-bold' : lan === 'cn' ? 'cn' : lan === 'tc' ? 'tc'  : lan === 'jp' ? 'jp' :  lan === 'de' ? 'en-bold' :lan === 'es' ? 'en-bold' : lan === 'it' ? 'en-bold' : 'kr'"> -->
  <div class="container" :class="lan === 'cn' ? 'cn' : lan === 'tc' ? 'tc' : lan === 'jp' ? 'jp' : lan === 'kr' ? 'kr' : 'en-bold'">
    <div v-if="screenWidth <= 500">
      <div class="top">
        <div class="top-bg1"></div>
        <div class="top-bg2"></div>
        <img class="top-banner" src="../assets/images/white-banner2.png" />
        <div class="lan-box">
          <div class="lan-bar" @click="openLanChoose">
            <span :class="lanText!=='简' && lanText!=='繁' ? 'en-nobold' : ''">{{ lanText }}</span>
            <img class="arrow-down" src="../assets/images/black-arrow1.png" />
          </div>
          <div class="lan-choose" v-if="showLanChoose">
            <span v-for="(item, index) in lanArr" :key="index" @click="chooseLan(item.name)" :class="[item.text === '简' ? 'cn' : item.text === '繁' ? 'tc' : 'en-nobold',lanText === item.text ? 'active' : '']">{{item.text}}</span>
          </div>
        </div>
        <div class="top-info">
          <div class="top-title">
            <span v-if="lan==='en'">MANUFACTURE CALIBRES</span>
            <span v-if="lan==='cn'">虚拟机芯</span>
            <span v-if="lan==='tc'">虛擬機芯</span>
            <span v-if="lan==='kr'" class="kr-bold">매뉴팩처 칼리버</span>
            <span v-if="lan==='jp'" class="jp-bold">ジャガー・ルクルト製キャリバー</span>
            <span v-if="lan==='de'">MANUFAKTURKALIBER</span>
            <span v-if="lan==='es'">CALIBRES DE LA MANUFACTURA</span>
            <span v-if="lan==='it'">I CALIBRI DELLA MANIFATTURA</span>
            <span v-if="lan==='fr'">Calibres Manufacture</span>
          </div>
          <div class="top-sub-title">
            <span v-if="lan==='en'" class="en-nobold">Click on the calibre of your choice to start the experience.</span>
            <span v-if="lan==='cn'">请点击您选择的机芯开始体验</span>
            <span v-if="lan==='tc'">請點擊您選擇的機芯開始體驗</span>
            <span v-if="lan==='kr'">칼리버를 선택하여 체험을 시작하세요.</span>
            <span v-if="lan==='jp'">ご希望のキャリバーをクリックして、さらに詳しくご覧ください。</span>
            <span v-if="lan==='de'" class="en-nobold">Klicken Sie auf das Kaliber Ihrer Wahl, um das Erlebnis zu beginnen.</span>
            <span v-if="lan==='es'" class="en-nobold">Haga clic en el calibre de su elección para iniciar la experiencia.</span>
            <span v-if="lan==='it'" class="en-nobold">Clicca su un calibro a scelta per iniziare l’esperienza.</span>
            <span v-if="lan==='fr'" class="en-nobold">Cliquez sur le calibre de votre choix pour commencer l’expérience.</span>
          </div>
        </div>
      </div>
      <div class="list">
        <div v-for="(item, index) in list " :key="index"  class="list-item" @click="toDetail(item.id, item.name)">
          <div class="list-image-wrap">
            <img class="list-image" :src="item.bottomPic" />
          </div>
          <div class="list-name">
            <span v-if="lan==='en'">CALIBRE {{ item.name }}</span>
            <span v-if="lan==='cn'">积家{{ item.name }}型机芯</span>
            <span v-if="lan==='tc'">積家{{ item.name }}型機芯</span>
            <span class="kr-bold" v-if="lan==='kr'">칼리버 {{ item.name }}</span>
            <span class="jp-bold" v-if="lan==='jp'">キャリバー {{ item.name }}</span>
            <span v-if="lan==='de'">KALIBER {{ item.name }}</span>
            <span v-if="lan==='es'">CALIBRE {{ item.name }}</span>
            <span v-if="lan==='it'">CALIBRO {{ item.name }}</span>
            <span v-if="lan==='fr'">CALIBRE {{ item.name }}</span>
          </div>
          <div class="list-detail en-nobold" v-if="lan==='en'">
            <span>{{item.overview[0]}} - {{item.overview[2]}} parts</span>
            <span>{{item.overview[1]}} power reserve</span>
            <span>{{item.overview[3]}}</span>
          </div>
          <div class="list-detail cn" v-if="lan==='cn'">
            <span>{{item.cnOverview[0]}} - {{item.cnOverview[2]}}零件</span>
            <span>{{item.cnOverview[1]}}动力储存</span>
            <span>{{item.cnOverview[3]}}</span>
          </div>
          <div class="list-detail tc" v-if="lan==='tc'">
            <span>{{item.tcOverview[0]}} - {{item.tcOverview[2]}}零件</span>
            <span>{{item.tcOverview[1]}}動力儲存</span>
            <span>{{item.tcOverview[3]}}</span>
          </div>
          <div class="list-detail kr" v-if="lan==='kr'">
            <span>{{item.krOverview[0]}} - {{item.krOverview[2]}}개 부품</span>
            <span>{{item.krOverview[1]}}시간 파워 리저브</span>
            <span>{{item.krOverview[3]}}</span>
          </div>
          <div class="list-detail jp" v-if="lan==='jp'">
            <span>{{item.jpOverview[0]}} - {{item.jpOverview[2]}} の部品</span>
            <span>{{item.jpOverview[1]}} のパワーリザーブ</span>
            <span>{{item.jpOverview[3]}}</span>
          </div>
          <div class="list-detail en-nobold" v-if="lan==='de'">
            <span>{{item.deOverview[0]}} - {{item.deOverview[2]}} Einzelteile</span>
            <span>{{item.deOverview[1]}} Stunden Gangreserve</span>
            <span>{{item.deOverview[3]}}</span>
          </div>
          <div class="list-detail en-nobold" v-if="lan==='es'">
            <span>{{item.esOverview[0]}} - {{item.esOverview[2]}} piezas</span>
            <span>{{item.esOverview[1]}} horas de reserva de marcha</span>
            <span>{{item.esOverview[3]}}</span>
          </div>
          <div class="list-detail en-nobold" v-if="lan==='it'">
            <span>{{item.itOverview[0]}} - {{item.itOverview[2]}} componenti</span>
            <span>Riserva di carica di {{item.itOverview[1]}} ore</span>
            <span>{{item.itOverview[3]}}</span>
          </div>
          <div class="list-detail en-nobold" v-if="lan==='fr'">
            <span>{{item.frOverview[0]}} - {{item.frOverview[2]}} composants</span>
            <span>{{item.frOverview[1]}} heures de réserve de marche</span>
            <span>{{item.frOverview[3]}}</span>
          </div>
          <div class="link">
            <span v-if="lan==='en'">DETAILS</span>
            <span v-if="lan==='cn'">细节</span>
            <span v-if="lan==='tc'">細節</span>
            <span v-if="lan==='kr'">자세히 보기</span>
            <span class="jp-bold" v-if="lan==='jp'">詳細</span>
            <span v-if="lan==='de'">DETAILS</span>
            <span v-if="lan==='es'">DETALLES</span>
            <span v-if="lan==='it'">DETTAGLI</span>
            <span v-if="lan==='fr'">Détails</span>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    
    <div class="mobile-only" v-if="screenWidth > 500">
      <img src="../assets/images/mobile_only.png" />
    </div>

  </div>

</template>

<script>
  import list from '@/utils/watch'
  import Footer from '@/components/footer'

  function buildList(lan) {
    var newList = list.slice(0)
    // if (lan ==='jp') { // 日语有的机芯不要显示
    //   newList = newList.filter(w => {
    //     return ['925/1', '174', '853A', '849A', '966'].indexOf(w.name) === -1
    //   })
    // }
    return newList
  }

  const isTest = location.pathname.indexOf('/test') > -1 || location.host.indexOf('192.168') > -1

  export default {
    components: {
      Footer
    },
    name: 'List',
    data() {
      return {
        showLanChoose: false,
        list: [],
        name: ''
      }
    },

    computed: {
      lan() {
        return this.$store.state.lan
      },
      lanText() {
        var index = this.lanArr.findIndex(l => l.name === this.lan)
        var text = this.lanArr[index].text
        return text
      },
      lanArr() {
        return this.$store.state.lanArr
      },
      screenWidth() {
        return this.$store.state.screenWidth
      }
    },
    mounted() {
      const me = this
      
      // console.log('location.pathname :>> ', location.pathname);
      // var pathname = location.pathname.replace(/\//g, '')
      var pathname = location.pathname.replace('test', '')
      var pathname = pathname.replace(/\//g, '')
      
      // const lanIndex = this.lanArr.findIndex(l => l.name === pathname)
      // console.log('pathname :>> ', pathname);
      const lanIndex = this.lanArr.findIndex(l => pathname.indexOf(l.name) !== -1)
      if (lanIndex > -1) {
        this.$store.commit('set_lan', pathname)
      } else {
        this.$store.commit('set_lan', 'en')
      }
      
      // 381 383 939 987 除了en显示 其他不显示
      // if (pathname !== 'en' && pathname !== 'kr' && pathname !== 'cn' && pathname !== 'tc' && pathname !== 'jp' && pathname !== '') {
      //    list.splice(13, 16)
      // }

      var screenWidth = document.body.clientWidth
      me.$store.commit('set_screenWidth', screenWidth)
      window.onresize = function() {
        var screenWidth = document.body.clientWidth
        me.$store.commit('set_screenWidth', screenWidth)
        // console.log('me.screenWidth :>> ', me.screenWidth);
      }

      me.list = buildList(me.lan)
    },
    methods: {
     
      toDetail(id, name) {
        var name = name.replace('/', '_')
        // console.log('name :>> ', name);
        this.$router.push({
          name: 'detail',
          // path: '/detail',
          query: {
            // id: id,
            name: name
          },
        })
      },
      chooseLan(name) {
        this.name = name
        this.showLanChoose = false
        this.$store.commit('set_lan', name)
        console.log('name :>> ', name);
        location.pathname = (isTest ? 'test/' : '') + name
        // location.pathname = 'test/' + name
       
      },
      openLanChoose() {
        this.showLanChoose = !this.showLanChoose
      },

    }
  }
</script>

<style scoped lang="less">
.top {
  width: 100%;
  height: 110.4vw;
  overflow: hidden;
  position: relative;
  // background: transparent url('https://s.desmix.com/jaeger/top-background') no-repeat;
  background: transparent url('../assets/images/top-background.png') no-repeat;
  background-position: 0% 0%;
  background-size: contain;
}
.top-bg1 {
  width: 100%;
  height: 33.4vw;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000CD 68%, #000000 100%) 0% 0% no-repeat padding-box;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  opacity: 0;
}
.top-bg2 {
  // position: absolute;
  width: 100%;
  // height: 76.8vw;
  height: 100vw;
  // bottom: 0;
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFFCF 64%, #FFFFFFF5 83%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}

.background {
  width: 100%;
}

.top-info {
  position: absolute;
  z-index: 100;
  bottom: 3.2vw;
  left: 5.33vw;
  width: 89.4vw;
  display: flex;
  flex-direction: column;
}

.top-title {
  width: 100%;
  margin-bottom: 2.66vw;
  font-size: 5.33vw;
  text-transform: uppercase;
}

.top-sub-title {
  font-size: 3.7vw;
  color: #767571;
}

.top-banner {
  position: absolute;
  top: 5vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  // width: 53vw;
  // height: 17.86vw;
  // width: 55.8vw;
  // height: 13.8vw;
  width: 40vw;
  height: 10vw;
}

.lan-box {
  
}
.lan-bar {
  // width: 100%;
  // height: 100%;
  display: flex;
  align-items: center;
  font-size: 2.933vw;
  width: 12vw;
  height: 6vw;
  justify-content: center;
  position: absolute;
  z-index: 100;
  top: 9vw;
  right: 3vw;
  background: rgba(228, 228, 228, 0.9);
  border-radius: 15px;
  box-shadow: 1px 1px 16px #96958f;
}
.lan-bar span {
  margin-right: 1.33vw;
  // color: #F6F6F6;
  color: #000;
}
.arrow-down {
  width: 2.13vw;
  height: 1.2vw;
}
.lan-choose {
  position: absolute;
  top: 11%;
  right: 3%;
  background-color: rgba(153, 153, 153, 0.6);
  // width: 100%;
  width: 23vw;
  // display: flex;
  // flex-direction: column;
  justify-content: space-around;
  // align-content: center;
  // align-items: center;
  padding: 3vw;
  line-height: 6.67vw;
  font-size: 2.933vw;
  flex-wrap: wrap;
  text-align: center;
  border-radius: 5px;
  backdrop-filter: blur(3px);
  z-index: 0;
  
  span{
    display: inline-block;
    width: 50%;
    padding: 1.5vw 0;
    color: #fff;
    float: left;
  }
  .active {
    color: #000;
  }
}



.list {
  width: 100%;
  padding: 5.33vw 5.33vw 6.33vw 5.33vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.list-item {
  width: 43.2vw;
  margin-bottom: 12.8vw;
}

.list-image-wrap {
  width: 43.2vw;
  height: 43.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4vw;
  background-color: #F4F4F4;
}
.list-image {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  // width: 37.3vw;
  // height: 37.3vw;
}

.list-name {
  font-size: 3.2vw;
  color: #201F1E;
  // font-weight: 600; 
  margin-bottom: 2.66vw;
}

.list-detail {
  width: 100%;
  color: #767571;
  font-size: 3.2vw;
  display: flex;
  // font-weight: 600;
  flex-direction: column;
  margin-bottom: 2.66vw;
}
.link {
  font-size: 2.4vw;
  color: #B7A98E;
  text-transform: uppercase;
}

</style>
