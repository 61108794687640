<template>
  <div class='copyright-box'>
    <div class="footer-link">
      <span @click="toCondition" v-if="lan==='en'">Terms and Conditions</span>
      <span @click="toCondition" v-if="lan==='cn'">条款和细则</span>
      <span @click="toCondition" v-if="lan==='tc'">條款及細則</span>
      <span @click="toCondition" v-if="lan==='kr'">이용 약관</span>
      <span class="jp-bold" @click="toCondition" v-if="lan==='jp'">利用規約</span>
      <span @click="toCondition" v-if="lan==='de'">AGB</span>
      <span @click="toCondition" v-if="lan==='es'">Términos y condiciones</span>
      <span @click="toCondition" v-if="lan==='it'">Termini e condizioni</span>
      <span @click="toCondition" v-if="lan==='fr'">Conditions générales</span>
      <span @click="toPolicy" v-if="lan==='en'">Privacy Policy</span>
      <span @click="toPolicy" v-if="lan==='cn'">隐私条款</span>
      <span @click="toPolicy" v-if="lan==='tc'">私隱條款</span>
      <span @click="toPolicy" v-if="lan==='kr'">개인정보 취급방침</span>
      <span class="jp-bold" @click="toPolicy" v-if="lan==='jp'">プライバシーポリシーをご参照ください</span>
      <span @click="toPolicy" v-if="lan==='de'">Datenschutzbestimmungen</span>
      <span @click="toPolicy" v-if="lan==='es'">Política de privacidad</span>
      <span @click="toPolicy" v-if="lan==='it'">Politica sulla privacy</span>
      <span @click="toPolicy" v-if="lan==='fr'">Politique de confidentialité</span>
    </div>
    <div class="copyright" v-if="lan==='en'">Copyright @ Jaeger-LeCoultre 2022</div>
    <div class="copyright" v-if="lan==='cn'">版权申明@Jaeger-LeCoultre积家2022年</div>
    <div class="copyright" v-if="lan==='tc'">版權所有@Jaeger-LeCoultre積家2022年</div>
    <div class="copyright" v-if="lan==='kr'">저작권 @ Jaeger-LeCoultre 2022</div>
    <div class="copyright jp-bold" v-if="lan==='jp'">著作権 @ ジャガー・ルクルト 2022</div>
    <div class="copyright" v-if="lan==='de'">Copyright @ Jaeger-LeCoultre 2022</div>
    <div class="copyright" v-if="lan==='es'">Copyright @ Jaeger-LeCoultre 2022</div>
    <div class="copyright" v-if="lan==='it'">Copyright @ Jaeger-LeCoultre 2022</div>
    <div class="copyright" v-if="lan==='fr'">Copyright @ Jaeger-LeCoultre 2022</div>
  </div>
</template>

<script>
export default {
  methods: {
    toCondition() {
      if (this.lan === 'en') {
        window.open('https://www.jaeger-lecoultre.com/ww-en/terms-and-conditions', '_blank')
      } else if (this.lan === 'cn') {
        window.open('https://www.jaeger-lecoultre.cn/cn-sc/terms-and-conditions', '_blank')
      } else if (this.lan === 'tc') {
        window.open('https://www.jaeger-lecoultre.com/hk-tc/terms-and-conditions', '_blank')
      } else if (this.lan === 'kr') {
        window.open('https://www.jaeger-lecoultre.com/kr-ko/terms-and-conditions', '_blank')
      } else if (this.lan === 'jp') {
        window.open('https://www.jaeger-lecoultre.com/jp-ja/terms-and-conditions', '_blank')
      } else if (this.lan === 'de') {
        window.open('https://www.jaeger-lecoultre.com/eu-de/terms-and-conditions', '_blank')
      } else if (this.lan === 'es') {
        window.open('https://www.jaeger-lecoultre.com/eu-es/terms-and-conditions', '_blank')
      } else if (this.lan === 'it') {
        window.open('https://www.jaeger-lecoultre.com/eu-it/terms-and-conditions', '_blank')
      } else if (this.lan === 'fr') {
        window.open('https://www.jaeger-lecoultre.com/eu-fr/terms-and-conditions', '_blank')
      }
      
     
    },
    toPolicy() {
      if (this.lan === 'en') {
        window.open('https://www.jaeger-lecoultre.com/ww-en/privacy-policy', '_blank')
      } else if (this.lan === 'cn') {
        window.open('https://www.jaeger-lecoultre.cn/cn-sc/privacy-policy', '_blank')
      } else if (this.lan === 'tc') {
        window.open('https://www.jaeger-lecoultre.com/hk-tc/privacy-policy', '_blank')
      } else if (this.lan === 'kr') {
        window.open('https://www.jaeger-lecoultre.com/kr-ko/privacy-policy', '_blank')
      } else if (this.lan === 'jp') {
        window.open('https://www.jaeger-lecoultre.com/jp-ja/privacy-policy', '_blank')
      } else if (this.lan === 'de') {
        window.open('https://www.jaeger-lecoultre.com/eu-de/privacy-policy', '_blank')
      } else if (this.lan === 'es') {
        window.open('https://www.jaeger-lecoultre.com/eu-es/privacy-policy', '_blank')
      } else if (this.lan === 'it') {
        window.open('https://www.jaeger-lecoultre.com/eu-it/privacy-policy', '_blank')
      } else if (this.lan === 'fr') {
        window.open('https://www.jaeger-lecoultre.com/eu-fr/privacy-policy', '_blank')
      }
    }
  },
  computed: {
    lan() {
      return this.$store.state.lan
    },
  }
}
</script>

<style scoped lang="less">
  .copyright-box {
    width: 100%;
    height: 20vw;
    background-color: #000;
    .footer-link {
      padding-top: 2vw;
      display: flex;
      justify-content: center;
      height: 50%;
      font-size: 3vw;
      color: #B2AFAB;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      span:first-child {
        margin-right: 5vw;
      }
    }
    .copyright {
      padding-top: 2vw;
      width: 100%;
      height: 50%;
      text-align: center;
      color: #CAC9C1;
      font-size: 1.5vw;
    }
  }
</style>