<template>
  <!-- <div class="container" :class="lan==='en' ? 'en-bold' : lan === 'cn' ? 'cn' : lan === 'tc' ? 'tc' : lan === 'jp' ? 'jp' : lan === 'de' ? 'en-bold' : lan === 'es' ? 'en-bold' : lan === 'it' ? 'en-bold' : 'kr'"> -->
  <div class="container" :class="lan === 'cn' ? 'cn' : lan === 'tc' ? 'tc' : lan === 'jp' ? 'jp' : lan === 'kr' ? 'kr' : 'en-bold'">
    <div v-if="screenWidth <= 500">
      <div class="top">
        <div class="top-bg1"></div>
        <div class="top-bg2"></div>
        <img class="back" @click="backHome" src="../assets/images/left-arrow.png" alt="" />
        <!-- <img v-if="currentItem" class="top-img" :src="currentItem.gif" /> -->
        <video ref="video" class="top-video"  x-webkit-airplay="true" x5-video-player-type="h5" webkit-playsinline="true" playsinline="true" autoplay="autoplay" loop preload="auto" muted>
        </video>
        <img class="top-banner" src="../assets/images/white-banner2.png"/>
        <div class="lan-box">
          <div class="lan-bar" @click="openLanChoose">
            <span :class="lanText!=='简' && lanText!=='繁' ? 'en-nobold' : ''">{{lanText}}</span>
            <img class="arrow-down" src="../assets/images/black-arrow1.png" />
          </div>
          <div class="lan-choose" v-if="showLanChoose">
            <span v-for="(item, index) in lanArr" :key="index" @click="chooseLan(item.name)"  :class="[item.text === '简' ? 'cn' : item.text === '繁' ? 'tc' : 'en-nobold',lanText === item.text ? 'active' : '']">{{item.text}}</span>
          </div>
        </div>
        <div v-if="currentItem" class="top-info">
          <div class="top-title">
            <span v-if="lan==='en'">CALIBRE {{currentItem.name}}</span>
            <span v-if="lan==='cn'">积家{{ currentItem.name }}型机芯</span>
            <span v-if="lan==='tc'">積家{{ currentItem.name }}型機芯</span>
            <span v-if="lan==='kr'" class="kr-bold">칼리버 {{ currentItem.name }}</span>
            <span v-if="lan==='jp'" class="jp-bold">キャリバー {{ currentItem.name }}</span>
            <span v-if="lan==='de'">KALIBER {{ currentItem.name }}</span>
            <span v-if="lan==='es'">CALIBRE {{ currentItem.name }}</span>
            <span v-if="lan==='it'">CALIBRO {{ currentItem.name }}</span>
            <span v-if="lan==='fr'">CALIBRE {{ currentItem.name }}</span>
          </div>
          <div class="top-sub-title">
            <span class="en-nobold" v-if="lan==='en'">{{currentItem.overview[0]}} - {{currentItem.overview[2]}} parts - {{currentItem.overview[1]}} power reserve - {{currentItem.overview[3]}}</span>
            <span v-if="lan==='cn'">{{currentItem.cnOverview[0]}} - {{currentItem.cnOverview[2]}}零件 - {{currentItem.cnOverview[1]}}动力储存 - {{currentItem.cnOverview[3]}}</span>
            <span v-if="lan==='tc'">{{currentItem.tcOverview[0]}} - {{currentItem.tcOverview[2]}}零件 - {{currentItem.tcOverview[1]}}動力儲存 - {{currentItem.tcOverview[3]}}</span>
            <span v-if="lan==='kr'">{{currentItem.krOverview[0]}} - {{currentItem.krOverview[2]}}개 부품 - {{currentItem.krOverview[1]}}시간 파워 리저브 - {{currentItem.krOverview[3]}}</span>
            <span v-if="lan==='jp'">{{currentItem.jpOverview[0]}} - {{currentItem.jpOverview[2]}}の部品 - {{currentItem.jpOverview[1]}}のパワーリザーブ - {{currentItem.jpOverview[3]}}</span>
            <span class="en-nobold" v-if="lan==='de'">{{currentItem.deOverview[0]}} - {{currentItem.deOverview[2]}} Einzelteile - {{currentItem.deOverview[1]}} Stunden Gangreserve - {{currentItem.deOverview[3]}}</span>
            <span class="en-nobold" v-if="lan==='es'">{{currentItem.esOverview[0]}} - {{currentItem.esOverview[2]}} piezas - {{currentItem.esOverview[1]}} horas de reserva de marcha - {{currentItem.esOverview[3]}}</span>
            <span class="en-nobold" v-if="lan==='it'">{{currentItem.itOverview[0]}} - {{currentItem.itOverview[2]}} componenti - Riserva di carica di {{currentItem.itOverview[1]}} ore - {{currentItem.itOverview[3]}}</span>
            <span class="en-nobold" v-if="lan==='fr'">{{currentItem.frOverview[0]}} - {{currentItem.frOverview[2]}} composants - {{currentItem.frOverview[1]}} heures de réserve de marche - {{currentItem.frOverview[3]}}</span>
          </div>
        </div>
      </div>
      <div class="to-outer" @click="toOuter">
        <span v-if="lan==='en'">DISCOVER IN 3D</span>
        <span v-if="lan==='cn'">探索3D模型</span>
        <span v-if="lan==='tc'">探索3D模型</span>
        <span v-if="lan==='kr'" class="kr-bold">3D로 자세히 보기</span>
        <span v-if="lan==='jp'" class="jp-bold">3Dで詳しく見る</span>
        <span v-if="lan==='de'">IN 3D ENTDECKEN</span>
        <span v-if="lan==='es'">DESCUBRIR EN 3D</span>
        <span v-if="lan==='it'">SCOPRI IN 3D</span>
        <span v-if="lan==='fr'">Découvrir en 3D</span>
      </div>
      <div class="description" v-if="currentItem">
        <div v-for="(item, index) in currentItem.description" v-if="lan==='en'" :key="index" class="en-nobold">{{item}}</div>
        <div v-for="(item, index) in currentItem.cnDescription" v-if="lan==='cn'" :key="index" class="cn">{{item}}</div>
        <div v-for="(item, index) in currentItem.tcDescription" v-if="lan==='tc'" :key="index" class="tc">{{item}}</div>
        <div v-for="(item, index) in currentItem.krDescription" v-if="lan==='kr'" :key="index" class="kr">{{item}}</div>
        <div v-for="(item, index) in currentItem.jpDescription" v-if="lan==='jp'" :key="index" class="jp">{{item}}</div>
        <div v-for="(item, index) in currentItem.deDescription" v-if="lan==='de'" :key="index" class="de en-nobold">{{item}}</div>
        <div v-for="(item, index) in currentItem.esDescription" v-if="lan==='es'" :key="index" class="es en-nobold">{{item}}</div>
        <div v-for="(item, index) in currentItem.itDescription" v-if="lan==='it'" :key="index" class="it en-nobold">{{item}}</div>
        <div v-for="(item, index) in currentItem.frDescription" v-if="lan==='fr'" :key="index" class="fr en-nobold">{{item}}</div>
      </div>
      <div class="function" v-if="currentItem">
        <div class="function-bar" @click="toggleFunction">
          <span v-if="lan==='en'">FUNCTIONS</span>
          <span v-if="lan==='cn'">功能</span>
          <span v-if="lan==='tc'">功能</span>
          <span v-if="lan==='kr'" class="kr-bold">기능</span>
          <span v-if="lan==='jp'" class="jp-bold">機能</span>
          <span v-if="lan==='de'">FUNKTIONEN</span>
          <span v-if="lan==='es'">FUNCIONES</span>
          <span v-if="lan==='it'">FUNZIONI</span>
          <span v-if="lan==='fr'">FONCTIONS</span>
          <img class="black-arrow" src="../assets/images/black-arrow-down.png" />
        </div>
        <div class="function-content" v-if="showFunctionContent">
          <div class="en-nobold" v-if="lan==='en'">
            <span v-for="(item, index) in currentItem.functions" :key="index">{{item}}</span>
          </div>
          <div class="cn" v-if="lan==='cn'">
            <span v-for="(item, index) in currentItem.cnFunctions" :key="index">{{item}}</span>
          </div>
          <div class="tc" v-if="lan==='tc'">
            <span v-for="(item, index) in currentItem.tcFunctions" :key="index">{{item}}</span>
          </div>
          <div class="kr" v-if="lan==='kr'">
            <span v-for="(item, index) in currentItem.krFunctions" :key="index">{{item}}</span>
          </div>
          <div class="jp" v-if="lan==='jp'">
            <span v-for="(item, index) in currentItem.jpFunctions" :key="index">{{item}}</span>
          </div>
          <div class="en-nobold" v-if="lan==='de'">
            <span v-for="(item, index) in currentItem.deFunctions" :key="index">{{item}}</span>
          </div>
          <div class="en-nobold" v-if="lan==='es'">
            <span v-for="(item, index) in currentItem.esFunctions" :key="index">{{item}}</span>
          </div>
          <div class="en-nobold" v-if="lan==='it'">
            <span v-for="(item, index) in currentItem.itFunctions" :key="index">{{item}}</span>
          </div>
          <div class="en-nobold" v-if="lan==='fr'">
            <span v-for="(item, index) in currentItem.frFunctions" :key="index">{{item}}</span>
          </div>
        </div>
      </div>
      <div class="watch-list" v-if="currentItem">
        <div class="watch-title">
          <span v-if="lan==='en'">WATCHES RELATED TO THIS CALIBRE</span>
          <span v-if="lan==='cn'">与此机芯相关的腕表</span>
          <span v-if="lan==='tc'">與此機芯相關的腕錶</span>
          <span v-if="lan==='kr'" class="kr-bold">해당 칼리버를 장착한 워치</span>
          <span v-if="lan==='jp'" class="jp-bold">このキャリバーに関連する時計</span>
          <span v-if="lan==='de'">ÄHNLICHE UHREN</span>
          <span v-if="lan==='es'">RELOJES ASOCIADOS A ESTE CALIBRE</span>
          <span v-if="lan==='it'">OROLOGI CORRELATI A QUESTO CALIBRO</span>
          <span v-if="lan==='fr'">MONTRES LIÉES À CE CALIBRE</span>
        </div>
        <div>
        </div>
        <div class="watch-swiper" v-if="currentItem" @touchstart="watchtouchstart" @touchmove="watchtouchmove"
            @touchend="watchtouchend" :style="{marginLeft: listMarginLeft + 'vw'}">
          <div class="watch-swiper-item">
            <div class="watch-image-box">
              <img :src="midPic1" />
            </div>
            <div class="watch-name">
              <span v-if="lan==='en'">{{currentItem.middlePics[0].name}}</span>
              <span v-if="lan==='en'">{{currentItem.middlePics[0].subName}}</span>
              <span v-if="lan==='cn'">{{currentItem.middlePics[0].cnName}}</span>
              <span v-if="lan==='cn'">{{currentItem.middlePics[0].cnSubName}}</span>
              <span v-if="lan==='tc'">{{currentItem.middlePics[0].tcName}}</span>
              <span v-if="lan==='tc'">{{currentItem.middlePics[0].tcSubName}}</span>
              <span v-if="lan==='kr'">{{currentItem.middlePics[0].krName}}</span>
              <span v-if="lan==='kr'">{{currentItem.middlePics[0].krSubName}}</span>
              <span class="jp-bold" v-if="lan==='jp'">{{currentItem.middlePics[0].jpName}}</span>
              <span class="jp-bold" v-if="lan==='jp'">{{currentItem.middlePics[0].jpSubName}}</span>
              <span v-if="lan==='de'">{{currentItem.middlePics[0].deName}}</span>
              <span v-if="lan==='de'">{{currentItem.middlePics[0].deSubName}}</span>
              <span v-if="lan==='es'">{{currentItem.middlePics[0].esName}}</span>
              <span v-if="lan==='es'">{{currentItem.middlePics[0].esSubName}}</span>
              <span v-if="lan==='it'">{{currentItem.middlePics[0].itName}}</span>
              <span v-if="lan==='it'">{{currentItem.middlePics[0].itSubName}}</span>
              <span v-if="lan==='fr'">{{currentItem.middlePics[0].frName}}</span>
              <span v-if="lan==='fr'">{{currentItem.middlePics[0].frSubName}}</span>
            </div>
          </div>
          <div class="watch-swiper-item">
            <div class="watch-image-box">
              <img :src="midPic2"  />       
            </div>
            <div class="watch-name">
              <span v-if="lan==='en'">{{currentItem.middlePics[1].name}}</span>
              <span v-if="lan==='en'">{{currentItem.middlePics[1].subName}}</span>
              <span v-if="lan==='cn'">{{currentItem.middlePics[1].cnName}}</span>
              <span v-if="lan==='cn'">{{currentItem.middlePics[1].cnSubName}}</span>
              <span v-if="lan==='tc'">{{currentItem.middlePics[1].tcName}}</span>
              <span v-if="lan==='tc'">{{currentItem.middlePics[1].tcSubName}}</span>
              <span v-if="lan==='kr'">{{currentItem.middlePics[1].krName}}</span>
              <span v-if="lan==='kr'">{{currentItem.middlePics[1].krSubName}}</span>
              <span class="jp-bold" v-if="lan==='jp'">{{currentItem.middlePics[1].jpName}}</span>
              <span class="jp-bold" v-if="lan==='jp'">{{currentItem.middlePics[1].jpSubName}}</span>
              <span v-if="lan==='de'">{{currentItem.middlePics[1].deName}}</span>
              <span v-if="lan==='de'">{{currentItem.middlePics[1].deSubName}}</span>
              <span v-if="lan==='es'">{{currentItem.middlePics[1].esName}}</span>
              <span v-if="lan==='es'">{{currentItem.middlePics[1].esSubName}}</span>
              <span v-if="lan==='it'">{{currentItem.middlePics[1].itName}}</span>
              <span v-if="lan==='it'">{{currentItem.middlePics[1].itSubName}}</span>
              <span v-if="lan==='fr'">{{currentItem.middlePics[1].frName}}</span>
              <span v-if="lan==='fr'">{{currentItem.middlePics[1].frSubName}}</span>
            </div>
          </div>
          <div class="watch-swiper-item">
            <div class="watch-image-box">
              <img :src="midPic3" />       
            </div>
            <div class="watch-name">
              <span v-if="lan==='en'">{{currentItem.middlePics[2].name}}</span>
              <span v-if="lan==='en'">{{currentItem.middlePics[2].subName}}</span>
              <span v-if="lan==='cn'">{{currentItem.middlePics[2].cnName}}</span>
              <span v-if="lan==='cn'">{{currentItem.middlePics[2].cnSubName}}</span>
              <span v-if="lan==='tc'">{{currentItem.middlePics[2].tcName}}</span>
              <span v-if="lan==='tc'">{{currentItem.middlePics[2].tcSubName}}</span>
              <span v-if="lan==='kr'">{{currentItem.middlePics[2].krName}}</span>
              <span v-if="lan==='kr'">{{currentItem.middlePics[2].krSubName}}</span>
              <span class="jp-bold" v-if="lan==='jp'">{{currentItem.middlePics[2].jpName}}</span>
              <span class="jp-bold" v-if="lan==='jp'">{{currentItem.middlePics[2].jpSubName}}</span>
              <span v-if="lan==='de'">{{currentItem.middlePics[2].deName}}</span>
              <span v-if="lan==='de'">{{currentItem.middlePics[2].deSubName}}</span>
              <span v-if="lan==='es'">{{currentItem.middlePics[2].esName}}</span>
              <span v-if="lan==='es'">{{currentItem.middlePics[2].esSubName}}</span>
              <span v-if="lan==='it'">{{currentItem.middlePics[2].itName}}</span>
              <span v-if="lan==='it'">{{currentItem.middlePics[2].itSubName}}</span>
              <span v-if="lan==='fr'">{{currentItem.middlePics[2].frName}}</span>
              <span v-if="lan==='fr'">{{currentItem.middlePics[2].frSubName}}</span>
            </div>
          </div>
        </div>
        <div class="watch-swiper-page">
          <div class="page-bar" :style="{ left: watchBarPosition + 'vw' }"></div>
        </div>
      </div>

      <div class="footer">
        <div class="footer-title">
          <span v-if="lan==='en'">OTHER CALIBRE</span>
          <span v-if="lan==='cn'">其他机芯</span>
          <span v-if="lan==='tc'">其他機芯</span>
          <span v-if="lan==='kr'" class="kr-bold">기타 칼리버 보기</span>
          <span v-if="lan==='jp'" class="jp-bold">その他のキャリバー</span>
          <span v-if="lan==='de'">ANDERES KALIBER</span>
          <span v-if="lan==='es'">OTRO CALIBRE</span>
          <span v-if="lan==='it'">ALTRO CALIBRO</span>
          <span v-if="lan==='fr'">AUTRE CALIBRE</span>
        </div>
        <div class="footer-swiper" @touchstart="footertouchstart" @touchmove="footertouchmove"
            @touchend="footertouchend" :style="{marginLeft: footerMarginLeft + 'vw'}">
            <div class="footer-swiper-item" v-for="item in bottomList" :key="item.id">
              <div class="bottom-item" @click="changeDetail(item.id, item.name)">
                <div class="bottom-image-box">
                  <img :src="item.bottomPic" />
                </div>

                <div class="bottom-title">
                  <span v-if="lan==='en'">CALIBRE {{item.name}}</span>
                  <span v-if="lan==='cn'">积家{{item.name}}型机芯</span>
                  <span v-if="lan==='tc'">積家{{item.name}}型機芯</span>
                  <span v-if="lan==='kr'">칼리버 {{item.name}}</span>
                  <span class="jp-bold" v-if="lan==='jp'">キャリバー {{item.name}}</span>
                  <span v-if="lan==='de'">KALIBER {{item.name}}</span>
                  <span v-if="lan==='es'">CALIBRE {{item.name}}</span>
                  <span v-if="lan==='it'">CALIBRO {{item.name}}</span>
                  <span v-if="lan==='fr'">CALIBRE {{ item.name }}</span>
                </div>
                <div class="bottom-detail en-nobold" v-if="lan==='en'">
                  <span>{{item.overview[0]}} - {{item.overview[2]}} parts</span>
                  <span>{{item.overview[1]}} power reserve</span>
                  <span>{{item.overview[3]}}</span>
                </div>
                <div class="bottom-detail" v-if="lan==='cn'">
                  <span>{{item.cnOverview[0]}} - {{item.cnOverview[2]}}零件</span>
                  <span>{{item.cnOverview[1]}}动力储存</span>
                  <span>{{item.cnOverview[3]}}</span>
                </div>
                <div class="bottom-detail" v-if="lan==='tc'">
                  <span>{{item.tcOverview[0]}} - {{item.tcOverview[2]}}零件</span>
                  <span>{{item.tcOverview[1]}}動力儲存</span>
                  <span>{{item.tcOverview[3]}}</span>
                </div>
                <div class="bottom-detail" v-if="lan==='kr'">
                  <span>{{item.krOverview[0]}} - {{item.krOverview[2]}}개 부품</span>
                  <span>{{item.krOverview[1]}}시간 파워 리저브</span>
                  <span>{{item.krOverview[3]}}</span>
                </div>
                <div class="bottom-detail" v-if="lan==='jp'">
                  <span>{{item.jpOverview[0]}} - {{item.jpOverview[2]}}の部品</span> 
                  <span>{{item.jpOverview[1]}}のパワーリザーブ</span>
                  <span>{{item.jpOverview[3]}}</span>
                </div>
                <div class="bottom-detail en-nobold" v-if="lan==='de'">
                  <span>{{item.deOverview[0]}} - {{item.deOverview[2]}} Einzelteile</span>
                  <span>{{item.deOverview[1]}} Stunden Gangreserve</span>
                  <span>{{item.deOverview[3]}}</span>
                </div>
                <div class="bottom-detail en-nobold" v-if="lan==='es'">
                  <span>{{item.esOverview[0]}} - {{item.esOverview[2]}} piezas</span>
                  <span>{{item.esOverview[1]}} horas de reserva de marcha</span>
                  <span>{{item.esOverview[3]}}</span>
                </div>
                <div class="bottom-detail en-nobold" v-if="lan==='it'">
                  <span>{{item.itOverview[0]}} - {{item.itOverview[2]}} componenti</span>
                  <span>Riserva di carica di {{item.itOverview[1]}} ore</span>
                  <span>{{item.itOverview[3]}}</span>
                </div>
                <div class="bottom-detail en-nobold" v-if="lan==='fr'">
                  <span>{{item.frOverview[0]}} - {{item.frOverview[2]}} composants</span>
                  <span>{{item.frOverview[1]}} heures de réserve de marche</span>
                  <span>{{item.frOverview[3]}}</span>
                </div>
                <div class="bottom-link">
                  <span v-if="lan==='en'">DETAILS</span>
                  <span v-if="lan==='cn'">细节</span>
                  <span v-if="lan==='tc'">細節</span>
                  <span v-if="lan==='kr'">자세히 보기</span>
                  <span class="jp-bold" v-if="lan==='jp'">詳細</span>
                  <span v-if="lan==='de'">DETAILS</span>
                  <span v-if="lan==='es'">DETALLES</span>
                  <span v-if="lan==='it'">DETTAGLI</span>
                  <span v-if="lan==='fr'">Détails</span>
                </div>
              </div>
            </div>
          </div>

        <div class="bottom-swiper-page">
          <div class="page-bar" :style="{left: BottomBarPosition + 'vw'}"></div>
        </div>
      </div>

      <div class="bottom">
        <img class="bottom-background" src="../assets/images/bottom-background.png" />
        <div class="bottom-info">
          <span v-if="lan==='en'">ENJOY UP TO 8 YEARS WARRANTY</span>
          <span v-if="lan==='en'">FOR YOUR JAEGER-LECOULTRE WATCH</span>
          <span v-if="lan==='cn'">最长可达 8 年的国际保修</span>
          <span v-if="lan==='tc'">最長可達 8 年的國際保修</span>
          <span v-if="lan==='kr'">최대 8년의 예거 르쿨트르 워치 품질보증을 제공합니다</span>
          <span class="jp-bold" v-if="lan==='jp'">最長8年間のジャガー・ルクルト時計保証をご活用ください</span>
          <span v-if="lan==='de'">ERHALTEN SIE BIS ZU 8 JAHRE GARANTIE AUF IHRE JAEGER-LECOULTRE UHR</span>
          <span v-if="lan==='es'">DISFRUTE DE HASTA 8 AÑOS DE GARANTÍA POR SU RELOJ JAEGER-LECOULTRE</span>
          <span v-if="lan==='it'">BENEFICIA DI UNA GARANZIA FINO A 8 ANNI PER IL TUO OROLOGIO JAEGER-LECOULTRE</span>
          <span v-if="lan==='fr'">BÉNÉFICIEZ DE JUSQU’À 8 ANS DE GARANTIE POUR VOTRE MONTRE JAEGER-LECOULTRE</span>
          <!-- <span v-if="lan==='cn'">FOR YOUR JAEGER-LECOULTRE WATCH</span> -->
        </div>
        <div class="to-link" @click="toLink">
          <span v-if="lan==='en'">DISCOVER MORE</span>
          <span v-if="lan==='cn'">发现更多</span>
          <span v-if="lan==='tc'">發現更多</span>
          <span v-if="lan==='kr'">더 자세히 보기</span>
          <span class="jp-bold" v-if="lan==='jp'">詳しく見る</span>
          <span v-if="lan==='de'">Mehr erfahren</span>
          <span v-if="lan==='es'">Más información</span>
          <span v-if="lan==='it'">Scopri di più</span>
          <span v-if="lan==='fr'">En savoir plus</span>
          <img src="../assets/images/arrow-right.png" alt="">
        </div>
      </div>
      <Footer></Footer>
    </div>

    <div class="mobile-only" v-if="screenWidth > 500">
      <img src="../assets/images/mobile_only.png" />
    </div>
    
  </div>

</template>

<script>
  import list from '@/utils/watch'
  import Footer from '@/components/footer'

  function buildList(lan) {
    var newList = list.slice(0)
    // if (lan ==='jp') { // 日语有的机芯不要显示
    //   newList = newList.filter(w => {
    //     return ['925/1', '174', '853A', '849A', '966'].indexOf(w.name) === -1
    //   })
    // }
    return newList
  }

  const isTest = location.pathname.indexOf('/test') > -1 || location.host.indexOf('192.168') > -1

  export default {
    components: {
      Footer
    },
    data() {
      return {
        list: buildList(this.$store.state.lan),
        showFunctionContent: false,
        showLanChoose: false,
        watchBarPosition: 0,
        BottomBarPosition: 0,
        currentItem: null,
        midPic1: '',
        midPic2: '',
        midPic3: '',
        bottomList: [],
        middleMoving: false,
        middleStartX: 0,
        middleMoveX: 0,
        listMarginLeft: 0,
        footerMoving: false,
        footerStartX: 0,
        footerMoveX: 0,
        footerMarginLeft: 5.33,
        watchName: ''
      }
    },
    computed: {
      lan() {
        return this.$store.state.lan
      },
      lanText() {
        var index = this.lanArr.findIndex(l => l.name === this.lan)
        var text = this.lanArr[index].text
        return text
      },
      lanArr() {
         // 381 383 939 987 只显示语言en
        // if (this.watchName === '381' || this.watchName === '383' || this.watchName === '939AA' || this.watchName === '987') {
        //   return this.$store.state.lanArr.slice(0,5)
        // }
        
        return this.$store.state.lanArr
      },
      screenWidth() {
        return this.$store.state.screenWidth
      },

    },
    mounted() {
      // 381 383 939 987 除了en显示 其他不显示
      var a =  location.href.split('?name=')
      console.log(a, this.lanArr)
      this.watchName = a[1]
        
      const me = this


      // console.log('location.pathname :>> ', location.pathname)
      // var pathname = location.pathname.replace(/\//g, '')
      var pathname = location.pathname.replace('test', '')
      var pathname = pathname.replace(/\//g, '')
      // const lanIndex = this.lanArr.findIndex(l => l.name === pathname)
      // console.log('pathname :>> ', pathname);
      const lanIndex = this.lanArr.findIndex(l => pathname.indexOf(l.name) !== -1)
      if (lanIndex > -1) {
        this.$store.commit('set_lan', pathname)
      } else {
        this.$store.commit('set_lan', 'en')
      }


      var screenWidth = document.body.clientWidth
      me.$store.commit('set_screenWidth', screenWidth)
      window.onresize = function() {
        var screenWidth = document.body.clientWidth
        me.$store.commit('set_screenWidth', screenWidth)
        // console.log('me.screenWidth :>> ', me.screenWidth);
      }

      var options = this.$route.query
      // console.log('options :>> ', options);

      if (options && (options.name || options.name === 0)) {
        // console.log('has Options.name');
        const index = this.list.findIndex(l => {
          var name = l.name.replace('/', '_')
          return options.name === name
        })
        // console.log('index :> ', index)
        this.changeCalibre(index)
      } else {
        // console.log('no Options.name');
        const index = Math.floor(Math.random() * (9 - 0)) + 0
        this.changeCalibre(index)
      }

    },
    methods: {
      backHome() {
        this.$router.push({
          name: 'list'
        })
      },
      changeDetail(id, name) {
        var name = name.replace('/', '_')
        this.$router.push({
          name: 'detail',
          // path: '/detail',
          query: {
            name: name,
          }
        })
      },
      openLanChoose() {
         this.showLanChoose = !this.showLanChoose
      
      },
      chooseLan(name) {
        this.showLanChoose = false
        this.$store.commit('set_lan', name)
        // console.log('name :>> ', name);
        location.pathname = (isTest ? 'test/' : '') + name
        // location.pathname = 'test/' + name
      },

      changeBottomSwiper(e) {
        const current = e.detail.current
        const position = current * 20 + '%'
        this.setData({
          BottomBarPosition: position,
        })
      },

      changeCalibre(index) {
        this.list = buildList(this.lan)
        document.documentElement.scrollTop = 0
        const me = this
        // let indexArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].filter(it => it !== index)
        // let indexArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(it => it !== index)
        let indexArr = []
        const listLen = me.list.length
        for(var i = 0; i < listLen; i++) {
          if (i !== index) {
            indexArr.push(i)
          }
        }
        // console.log('indexArr: ', indexArr)
        indexArr.sort(function (a, b) {
          return 0.5 - Math.random()
        })
        let blist = []
        indexArr.forEach(it => {
          blist.push(me.list[it])
        })
        let item = JSON.parse(JSON.stringify(this.list[index]))
        for (let i = 0; i < item.description.length; i++) {
          item.description[i] = this.titleCase(item.description[i])
        }
        this.currentItem = item
        this.$refs['video'].src = this.currentItem.video
        this.midPic1 = item.middlePics[0].src
        this.midPic2 = item.middlePics[1].src
        this.midPic3 = item.middlePics[2].src
        this.bottomList = blist
      },
      toggleFunction() {
        this.showFunctionContent = !this.showFunctionContent
      },
      toOuter() {
        let name = this.currentItem.name
        // console.log('name :>> ', name);
        if (!name) {
          return
        }
        // if (name === '854/A') {
        //   name = '854A/2'
        // }
        var lan = this.lan
        // if (lan === 'tc') {
        //   lan = 'tr'
        // }
        // if (lan === 'kr') {
        //   lan = 'ko'
        // }

        // window.open('https://calibre.jaeger-lecoultre.com/model/index.html?m=' + name + '&l=' + lan, '_self')
        window.open('https://calibre.jaeger-lecoultre.com/' + (isTest ? 'test/' : '') + 'model/index.html?m=' + name + '&l=' + lan, '_self')
      },
      toLink() {
        var lan = this.lan
        switch (lan) {
          case 'en':
            var url = 'https://www.jaeger-lecoultre.com/ch/en/customer-services/care-program.html'
            break
          case 'cn':
            var url = 'https://www.jaeger-lecoultre.cn/cn/sc/customer-services/care-program.html'
            break;
          case 'tc':
            var url = 'https://www.jaeger-lecoultre.com/hk-tc/services/jaeger-lecoultre-care-program'
            break;
          case 'kr':
            var url = 'https://www.jaeger-lecoultre.com/kr-ko/services/jaeger-lecoultre-care-program'
            break;
          case 'jp':
            var url = 'https://www.jaeger-lecoultre.com/jp-ja/services/jaeger-lecoultre-care-program'
            break;
          case 'de':
            var url = 'https://www.jaeger-lecoultre.com/eu-de/services/jaeger-lecoultre-care-program'
            break; 
          case 'es':
            var url = 'https://www.jaeger-lecoultre.com/eu-es/services/jaeger-lecoultre-care-program'
            break; 
          case 'it':
            var url = 'https://www.jaeger-lecoultre.com/eu-it/services/jaeger-lecoultre-care-program'
            break; 
          case 'fr':
            var url = 'https://www.jaeger-lecoultre.com/eu-fr/services/jaeger-lecoultre-care-program'
            break;
        }
        window.open(url, '_blank')
      },
      // 转换描述字符里的大小写
      titleCase(str) {
        if (['383', '381', '939AA'].indexOf(this.watchName) > -1) {
          return str
        }

        var arr = str.split(" ");
        var _length = arr.length;
        for (var i = 0; i < _length; i++) {
          if (i === 0 || (arr[i - 1].indexOf('.') !== -1 && arr[i - 1] !== '18.75')) {
            arr[i] = arr[i][0].toUpperCase() + arr[i].substring(1, arr[i].length).toLowerCase();
          } else if (arr[i] === '849A') {
            // 保持原样
          } else if (arr[i] === 'JAEGER-LECOULTRE') {
            arr[i] = 'Jaeger-LeCoultre'
          } else if (arr[i] === "LECOULTRE'S") {
            arr[i] = "LeCoultre's"
          } else if (arr[i] === 'REVERSO,') {
            arr[i] = 'Reverso,'
          } else if (arr[i] === 'REVERSO') {
            arr[i] = 'Reverso'
          } else if (arr[i] === 'DUOFACE,') {
            arr[i] = 'Duoface,'
          } else if (arr[i] === 'MEMOVOX.') {
            arr[i] = 'Memovox.'
          } else if (arr[i] === 'DUOMÈTRE') {
            arr[i] = 'D' + 'UOMÈTRE'.toLowerCase()
          } else if (arr[i] === 'Duoface' || arr[i] === '853A' || arr[i] === 'Moon' || arr[i] === 'Calibre' || arr[i] === 'Reverso.') {
            // arr[i] = 'Duoface'
          } else {
            arr[i] = arr[i].toLowerCase();
          }
        }
        str = arr.join(" ");
        return str;
      },
      watchtouchstart(e) {
        this.middleMoving = false
        // console.log('touchstart');
        this.middleStartX = e.touches[0].clientX
      },
      watchtouchmove(e) {
        // console.log('touchmove');
        this.middleMoveX = e.touches[0].clientX
        if (this.middleStartX - this.middleMoveX <= -100) { 
          // console.log('往右滑');
          // console.log('this.listMarginLeft :>> ', this.listMarginLeft);
          if (this.middleMoving) {
            return
          }
          if (this.listMarginLeft >= 0) {
            return
          }
          this.middleMoving = true
          this.listMarginLeft += 45.86
          this.watchBarPosition -= 45
          
        } else if (this.middleStartX - this.middleMoveX >= 100) { 
          // console.log('往左滑');
          // console.log('this.listMarginLeft :>> ', this.listMarginLeft);
          if (this.middleMoving) {
            return
          }
          if (this.listMarginLeft <= -40) {
            return
          }
          this.middleMoving = true
          this.listMarginLeft -= 45.86
          this.watchBarPosition += 45
        }
      },
      watchtouchend(e) {
        // console.log('touchend');
        this.middleMoving = false
      },
      footertouchstart(e) {
        this.footerMoving = false
        // console.log('touchstart');
        this.footerStartX = e.touches[0].clientX
      },
      footertouchmove(e) {
        // console.log('touchmove');
        this.footerMoveX = e.touches[0].clientX
        var moveLenth = this.list.length - 4
        if (this.footerStartX - this.footerMoveX <= -100) { //往右滑
          // console.log('往右滑');
          // console.log('this.footerMarginLeft :>> ', this.footerMarginLeft);
          if (this.footerMoving) {
            return
          }
          if (this.footerMarginLeft >= 0) {
            return
          }
          this.footerMoving = true
          this.footerMarginLeft += 45.86
          this.BottomBarPosition -= 69.33 / (moveLenth + 1)
          // this.BottomBarPosition -= 10
          
        } else if (this.footerStartX - this.footerMoveX >= 100) { //往左滑
          // console.log('往左滑');
          // console.log('this.footerMarginLeft :>> ', this.footerMarginLeft);
          if (this.footerMoving) {
            return
          }
          if (this.footerMarginLeft <= -45.86 * moveLenth) {
          // if (this.footerMarginLeft <= -300) {
            return
          }
          this.footerMoving = true
          this.footerMarginLeft -= 45.86
          this.BottomBarPosition += 69.33 / (moveLenth + 1)
          // this.BottomBarPosition += 10
        }
        // console.log('this.footerMarginLeft :>> ', this.footerMarginLeft);
      },
      footertouchend(e) {
        // console.log('touchend');
        this.footerMoving = false
      },
    }
  }
</script>

<style scoped lang="less">
.container {
  width: 100vw;
  overflow-x: hidden;
}
.top {
  width: 100vw;
  height: 111.73vw;
  overflow: hidden;
  position: relative;
  margin-bottom: 5.33vw;

}


.top-bg1 {
  width: 100%;
  height: 120vw;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000CD 68%, #000000 100%) 0% 0% no-repeat padding-box;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  opacity: 0;
}
.top-bg2 {
  position: absolute;
  width: 100%;
  z-index: 20;
  top: 73.6vw;
  // height: 78vw;
  height: 50vw;
  // height: 586rpx;
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFFCF 34%, #FFFFFFF5 53%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
} 
.top-video,
.top-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.back {
  position: absolute;
  z-index: 100;
  top: 10.26vw;
  left: 6vw;
  width: 4.5vw;
  height: 4.5vw;
}

.background {
  width: 100%;
}

.top-banner {
  position: absolute;
  // top: 0;
  top: 5vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  // width: 53.06vw;
  // height: 17.86vw;
  // width: 55.8vw;
  // height: 13.8vw;
  width: 40vw;
  height: 10vw;
}
.lan-box {
  // position: absolute;
  // z-index: 100;
  // top: 10.26vw;
  // right: 6vw;
}
.lan-bar {
  display: flex;
  align-items: center;
  font-size: 2.933vw;
  width: 12vw;
  height: 6vw;
  justify-content: center;
  position: absolute;
  z-index: 100;
  top: 9vw;
  right: 3vw;
  background: rgba(228, 228, 228, 0.9);
  border-radius: 15px;
  box-shadow: 1px 1px 16px #96958f;
}
.lan-bar span {
  margin-right: 1.33vw;
  color: #000;
}
.arrow-down {
  width: 2.13vw;
  height: 1.2vw;
}
.lan-choose {
  position: absolute;
  top: 11%;
  right: 3%;
  background-color: rgba(153, 153, 153, 0.6);
  // width: 100%;
  width: 23vw;
  // display: flex;
  // flex-direction: column;
  justify-content: space-around;
  // align-content: center;
  // align-items: center;
  padding: 3vw;
  line-height: 6.67vw;
  font-size: 2.933vw;
  flex-wrap: wrap;
  text-align: center;
  border-radius: 5px;
  backdrop-filter: blur(3px);
  z-index: 0;
  span{
    display: inline-block;
    width: 50%;
    padding: 1.5vw 0;
    color: #fff;
    float: left;
  }
  .active {
    color: #000;
  }
}

.top-info {
  position: absolute;
  z-index: 100;
  bottom: 3.2vw;
  left: 5.33vw;
  width: 89.33vw;
  display: flex;
  flex-direction: column;
}

.top-title {
  width: 100%;
  margin-bottom: 2.66vw;
  font-size: 6.4vw;
  // font-weight: 600;
}

.top-sub-title {
  display: flex;
  flex-direction: column;
  font-size: 3.73vw;
  color: #767571;
}

.to-outer {
  width: 89.33vw;
  height: 10.13vw;
  background-color: #3A3935;
  text-align: center;
  line-height: 10.13vw;
  color: #F3F1ED;
  // font-weight: 600;
  font-size: 3.2vw;
  margin: 0 auto 8vw auto;
  text-transform: uppercase;
}

.description {
  width:89.33vw;
  margin: 0 auto 9.6vw auto;
  font-size: 3.73vw;
  color: #3A3935;
  line-height: 6.13vw;
}

.function {
  width:89.33vw;
  margin: 0 auto 8.53vw auto;
  position: relative;

}
.function-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7.2vw;
  border-bottom: 1px solid #EBEBEB;
}

.function-bar span {
  font-size: 4vw;
  color: #3A3935;
  // font-weight: 600;
}
.function-bar img {
  width: 4.8vw;
  height: 2.8vw;
}
.function-content {
  margin-bottom: 5.33vw;
  transition: all 0.3s ease-out;
}
.function-content div {
  display: flex;
  flex-direction: column;
}
.function-content span {
  font-size: 3.73vw;
  line-height: 6.13vw;
  color: #3A3935;
}

.watch-list {
  margin-left: 5.33vw;
  margin-bottom: 10.66vw;
}
.watch-title {
  font-size: 4vw;
  color: #3A3935;
  // font-weight: 600;
  margin-bottom: 4vw;
}

.watch-swiper {
  display: flex;
  // width: 100vw;
  min-height: 66.67vw;
  // overflow: hidden;
  transition: all .3s ease-in-out;
  // font-weight: 600;
}

.watch-swiper-item {
  width: 43.2vw;
  margin-right: 2.66vw;
}
.watch-image-box {
  width: 43.2vw;
  height: 43.2vw;
  background-color: #F4F4F4;
  margin-bottom: 4vw;
}

.watch-image-box img {
  // width: 100%;
  // height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
.watch-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
}
.watch-name span {
  // text-align: center;
  text-transform: uppercase;
  font-size: 3.2vw;
  line-height: 4.26vw;
  margin-bottom: 0.8vw;
}
.watch-name span:first-child {
  color: #3A3935;
}
.watch-name span:last-child {
  color: #B7A98E;
}

.watch-swiper-page {
  position: relative;
  margin: 0 auto;
  width: 89.33vw;
  height: 0.66vw;
  background-color: #c1c1c0;
}
.watch-swiper-page .page-bar {
  position: absolute;
  z-index: 10;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: #33322e;
  transition: all 0.5s;
}




.footer {
  position: relative;
  width: 100%;
  padding-bottom: 10.66vw;
  /* height: 810.66vw; */
  background-color: #F4F4F4;
  padding-top: 5.33vw;
}

.footer-title {
  margin-left: 5.33vw;
  margin-bottom: 5.33vw;
  font-size: 4vw;
  color: #3A3935;
  // font-weight: 600;
}

.footer-swiper {
  position: relative;
  // width: 250vw;
  // height: 66.66vw;
  height: 85vw;
  display: flex;
  overflow: hidden;
  transition: all .3s ease-in-out;
  // font-weight: 600;
}

.footer-swiper-item {
  width: 43.2vw;
  margin-right: 2.66vw;
}

.bottom-item {
  width: 43.2vw; 
  margin-right: 2.66vw;
  display: flex;
  flex-direction: column;
}

.bottom-image-box {
  width: 43.2vw;
  height: 43.2vw;
  background-color: #E3E3E3;
  margin-bottom: 4vw;
}

.bottom-image-box img {
  // width: 100%;
  // height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.bottom-title {
  width: 100%;
  font-size: 3.2vw;
  color: #3A3935;
  margin-bottom: 0.8vw;
}

.bottom-detail {
  width: 100%;
  color: #767571;
  font-size: 3.2vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.66vw;
}
.bottom-link {
  font-size: 2.4vw;
  color: #B7A98E;
  text-transform: uppercase;
}



.bottom-swiper-page {
  position: relative;
  margin: 0 auto;
  width: 89.33vw;
  height: 0.66vw;
  background-color: #c1c1c0;
}
.bottom-swiper-page .page-bar {
  position: absolute;
  top: 0;
  width: 20vw;
  height: 100%;
  background-color: #33322e;
  transition: all 0.5s;
}

.bottom {
  width: 100%;
  height: 97.2vw;
  background-color: #1C1C1C;
  padding-left: 5.33vw;
  padding-top: 6.66vw;
}

.bottom-background {
  width: 89.33vw;
  height: 50vw;
  margin-bottom: 4.26vw;
}

.bottom-info {
  width: 89.33vw;
  color: #F3F1ED;
  display: flex;
  flex-direction: column;
  margin-bottom: 5.33vw;
}

.bottom-info span {
  font-size: 4vw;
  line-height: 5.33vw;
  // font-weight: 600;
}

.to-link {
  display: flex;
  align-items: center;
  color: #B7A98E;
  font-size: 3.2vw;
  text-transform: uppercase;
  // font-weight: 600;
  span {
    // margin-right: 2vw;
  }
  img {
    width: 6vw;
    height: 3vw;
  }
}

</style>

