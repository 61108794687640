import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lan: 'en',
    lanArr:[
      {name: 'en', text:'EN'},
      {name: 'kr', text:'KO'},
      {name: 'cn', text:'简'},
      {name: 'tc', text:'繁'},
      {name: 'jp', text:'JP'},
      {name: 'de', text:'DE'}
      ,
      {name: 'it', text:'IT'},
      {name: 'fr', text:'FR'},
      {name: 'es', text:'ES'},
      
    ],
    screenWidth: null
  },
  mutations: {
    set_lan(state, lan) {
      state.lan = lan
    },
    set_type(state, type) {
      state.type = type
    },
    set_screenWidth(state, data) {
      state.screenWidth = data
    }
  }
})
