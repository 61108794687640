<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link>Home</router-link>
    </div> -->
    <router-view :key="key"/>
  </div>
</template>

<script>
export default {
  computed: {
    key() {
      return this.$route.name ? this.$route.name + new Date() : this.$route + new Date()
    }
  }
}
</script>

<style lang="less">

  @font-face {
    font-family: 'NotoSansJP-Bold';
    src: url('https://d2tvf5266tnt8y.cloudfront.net/en/fonts/NotoSansJP-Bold.otf ');
  }

  @font-face {
    font-family: 'NotoSansJP-Regular';
    src: url('https://d2tvf5266tnt8y.cloudfront.net/en/fonts/NotoSansJP-Regular.otf');
  }

  @font-face {
    font-family: 'GothamSSmBold';
    // src: url('https://s.desmix.com/JAEGER-LECOULTRE/GothamSSm-Bold.ttf');
    src: url('https://d2tvf5266tnt8y.cloudfront.net/en/fonts/GothamSSm-Bold.2071eb32.ttf');
  }

  @font-face {
    font-family: 'GothamSSmBook';
    // src: url('https://s.desmix.com/JAEGER-LECOULTRE/GothamSSm-Book.ttf');
    src: url('https://d2tvf5266tnt8y.cloudfront.net/en/fonts/GothamSSm-Book.40ae923b.ttf');
  }

  @font-face {
    font-family: 'SourceHanSansSCBold';
    // src: url('https://s.desmix.com/JAEGER-LECOULTRE/SourceHanSansSC-Bold.ttf');
    src: url('https://d2tvf5266tnt8y.cloudfront.net/en/fonts/SourceHanSansSC-Bold.019c4736.ttf');
  }
  @font-face {
    font-family: 'Semibold';
    // src: url('https://s.desmix.com/JAEGER-LECOULTRE/SourceHanSansSC-Bold.ttf');
    src: url('https://d2tvf5266tnt8y.cloudfront.net/en/fonts/Hoefler-DecimalSemibold.241896cd.otf');
  }

  @font-face {
    font-family: 'NotoSansKR-Regular';
    src: url('https://d2tvf5266tnt8y.cloudfront.net/en/fonts/NotoSansKR-Regular.otf');
  }
  @font-face {
    font-family: 'NotoSansKR-Bold';
    src: url('https://d2tvf5266tnt8y.cloudfront.net/en/fonts/NotoSansKR-Bold.otf');
  }
  
  html,body {
    padding: 0;
    margin: 0;
  }
  * {
    box-sizing: border-box;
  }

  div {
    box-sizing: border-box;
  }

  .en-nobold {
    font-family: 'GothamSSmBook';
  }
  
  // .en-bold {
  //   font-family: 'GothamSSmBold';
  // }
  .en-bold {
    font-family: 'Semibold';
  }

  .cn {
    font-family: 'SourceHanSansSCBold';
  }
 
  .tc {
    font-family: 'SourceHanSansSCBold';
  }

  .kr {
    font-family: 'NotoSansKR-Regular';
  }
  .kr-bold {
    font-family: 'NotoSansKR-Bold';
    font-weight: 600;
  }
  .jp {
    font-family: 'NotoSansJP-Regular';
  }
  .jp-bold {
    font-family: 'NotoSansJP-Bold';
  }
  img {
    display: block;
  }

  .mobile-only {
    position: fixed;
    background-color: #000;
    width: 100%;
    height: 100vh;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
    }
    
  }



</style>
